import { Flex, forwardRef, Tab, TabProps, Text, TextProps } from "@chakra-ui/react";

export const GlowText: React.FC<TextProps & {intensity?: number}> = ({intensity, ...props}) => (
  <Flex position='relative'>
    <Text 
      position='absolute'
      userSelect='none'
        filter='blur(3px)'
        {...props}
        opacity= {intensity || 0.4}
  />
    <Text {...props}/>
  </Flex>
)
export const GlowTab = forwardRef(({ children, ...props }: TabProps, ref) => (
  <Tab ref={ref} opacity={0.5} _selected={{ opacity: 1 }} px={2} {...props}>
    <GlowText fontFamily='Russo One' fontSize={['1rem', '1.4rem']}>
      {children}
    </GlowText>
  </Tab>
))

export const SolidTab = (props: TabProps) => (
  <Tab
    px={8}
    fontFamily='Russo One'
    fontSize='sm'
    color='whiteAlpha.800'
    background='whiteAlpha.200'
    _selected={{ background: 'whiteAlpha.300' }}
    {...props}
  />
)



export const GlowInfo = (props: TextProps) => <GlowText textAlign='center' fontSize={['1.2rem', '1.2rem', '1.3rem']} {...props} />


export const StatusText = (props: TextProps) => <Text fontSize='sm' p={1} opacity={0.7} {...props} />