import { UploadFileFunc } from "../types"
import { Field, isField, isRecordField, RecordField } from "../types/forms"

export const processData = async (
  user: any, 
  field: any, 
  uploadFile: UploadFileFunc, 
  data?: any
  ) => {
  const acc: Record<string, any> = {}
  const entries = Object.entries(field.children)
  let numChildren = entries.length
  for (let i = 0; i < numChildren; i++) {
    const [fieldKey, child] = entries[i]
    if (isField(child as Field)) {
      switch ((child as Field)._type) {
        case 'file':
          acc[fieldKey] = await uploadFile(user, child, data?.[fieldKey])
          break
        case 'boolean':
          acc[fieldKey] = data?.[fieldKey] || false
          break
        case 'currency':
          let asNum = parseFloat(data?.[fieldKey] || '')
          if(isNaN(asNum)) acc[fieldKey] = undefined
          else acc[fieldKey] = asNum
          break;
        default:
          acc[fieldKey] = data?.[fieldKey] || ''
          break
      }
    } else if (isRecordField(child as RecordField)) {
      acc[fieldKey] = data?.[fieldKey] || []
    } else {
      acc[fieldKey] = await processData(user, child, data?.[fieldKey])
    }
  }
  return acc
}

export const recordToArray = (record?: Record<string, any>) =>
  Object.entries(record || {}).map(([id, val]) => ({ ...val, id }))
export const arrayToRecord = (
  arr?: Array<{ id: string } & Record<string, any>>
) =>
  (arr || []).reduce(
    (acc, curr) => ({
      ...acc,
      [curr.id]: curr,
    }),
    {} as Record<string, any>
  )

