import {
  Box, Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalProps,
  Text
} from '@chakra-ui/react'
import { useMyPlayer } from '../../../../api/players'
import { useMyUser } from '../../../../api/users'
import { useApp } from '../../../../hooks'
import { Copy } from '../../../shared/Copy'
import { Step } from '../Step'


export const VerificationModal = (props: Pick<ModalProps, 'onClose' | 'isOpen'>) => {
  const { account } = useApp()
  const {player} = useMyPlayer()
  const uid = account?.auth?.data?.uid
  const { user } = useMyUser()
  const { isVerified } = player || {}
  return (
    <Modal {...props} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody py={4}>
          <Step isComplete={!!isVerified}>
            {isVerified ? 'Verified' : 'Pending verification'}
          </Step>
          <Box mt={2} pt={2} borderTop='1px solid rgba(255,255,255,0.4)'>
            <Text color='whiteAlpha.900'>
              To verify your identity, DM me on Twitter (@tofeezy) with your
              user ID
            </Text>
            <Copy text={uid}>
              <b>USER ID:</b> {uid || ''}
            </Copy>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

