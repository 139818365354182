import { Box, Grid, Text, TextProps, VStack } from "@chakra-ui/react"
import { FC } from "react"
import { Form as FinalForm } from 'react-final-form'
import { FormElement } from "../forms/input"

const Amount: FC = ({children}) => <code style={{margin: '0 0.25rem', padding: '0 0.25rem', background: '#ffffff11'}}>{children}</code>
const RowHeader = (props: TextProps) => <Text fontWeight={600} fontSize='xs' {...props}/>
export const PayoutCalculator = () => {

  return(
    <VStack spacing={1} pb={3} align='flex-start' w='100%'>
      <Text fontSize='sm' opacity={0.7} letterSpacing='0.1rem' fontWeight={600} w='100%' pl={2}>PAYOUT CALCULATOR</Text>
      <FinalForm subscription={{values: true}} onSubmit={async () => {}}>
        {({values}) => {
          let parsed = parseFloat(values?.value || '')
          if(isNaN(parsed)) parsed = 10
          const rateTag = <Amount>${parsed.toFixed(2)}</Amount>
          return(
            <>
        <FormElement field={{
          _type: 'currency', 
          placeholder: 'Rate',
          }}
        />
        <Box w='100%' p={1}>
        <Grid rowGap={2} columnGap={2} templateColumns = 'auto 1fr' p={3} background='whiteAlpha.200' borderRadius={6} w='100%'>
        <RowHeader>SESSION RATE</RowHeader>
        <Text>{rateTag}</Text>
        <RowHeader>PLAYER</RowHeader>
        <Text>{rateTag} x <Amount>90%</Amount> - <Amount>30¢</Amount> = <Amount>${(parsed*0.9 - 0.3).toFixed(2)}</Amount></Text>
        <RowHeader>WEB DEV</RowHeader>
        <Text>{rateTag} x <Amount>5%</Amount> = <Amount>${(parsed*0.05).toFixed(2)}</Amount></Text>
        <RowHeader>SLIPPI DEVS</RowHeader>
        <Text>{rateTag} x <Amount>2.1%</Amount> = <Amount>${(parsed*0.021).toFixed(2)}</Amount></Text>
        <RowHeader>STRIPE</RowHeader>
        <Text>{rateTag} x <Amount>2.9%</Amount> + <Amount>30¢</Amount> = <Amount>${(parsed*0.029 + 0.3).toFixed(2)}</Amount></Text>
        </Grid>
        </Box>

        </>
        )
        } }
      </FinalForm>
    </VStack>
  )
}