import { Flex, VStack } from "@chakra-ui/react";
import { getDateTimeString, Payment } from "@testme/shared";
import { FC, useMemo } from "react";
import { StatusText } from "../shared/Text";


export const PaymentView:FC<{payment?: Payment}> = ({payment}) => {
  const {paidOnText, statusText} = useMemo(() => {
    if(!payment) return {paidOnText: '', statusText: ''}
    return {
      paidOnText: `Paid on ${getDateTimeString(payment.createdOn)}`,
      statusText: payment.status.toUpperCase()
    }
  }, [payment])
  return(
    <VStack w='100%' align='flex-start' p={2} spacing={0}>
      <Flex align='center' w='100%'>
      <StatusText color='#00ff00' fontWeight={600}>PAYMENT</StatusText>
      <StatusText ml='auto' fontSize='xs' fontWeight={600}>PRIVATE</StatusText>
      </Flex>
      <StatusText>{statusText}</StatusText>
      <StatusText>{paidOnText}</StatusText>
    </VStack>
  )
}