import {
  Button,
  Center,
  Flex,
  FlexProps,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { useFirestoreDocumentData } from '@react-query-firebase/firestore'
import {
  Player,
  Session,
  SessionStatus,
  sessionStatusText,
} from '@testme/shared'
import { doc, DocumentReference } from 'firebase/firestore'
import { animate, motion, useMotionValue } from 'framer-motion'
import { createContext, FC, useContext, useEffect, useMemo, useState } from 'react'
import { db } from '../../api/db'
import { getPlayerDoc } from '../../api/players'
import { endSession, startSession } from '../../api/session'
import { colors } from '../../constants'
import { useApp } from '../../hooks'
import { useIsMounted } from '../../hooks/useIsMounted'
import { PlayerPreview } from '../players/PlayerPreview'
import { ExpandOnMount } from '../shared/ExpandOnMount'
import { LoadingOverlay } from '../shared/LoadingOverlay'
import { GlowText } from '../shared/Text'

const MotionText = motion(Text)

const SessionContext = createContext<{
  id?: string
  session?: Session
  player?: Player
  challenger?: Player
  challengerLoading: boolean
  playerLoading: boolean
}>({
  session: {
    challenger: '',
    createdOn: 0,
    format: null,
    chatId: '',
    player: '',
    status: 0,
  },
  challengerLoading: false,
  playerLoading: false,
})
const useSession = () => useContext(SessionContext)
const useSessionState = (id?: string) => {
  const { data: session, refetch } = useFirestoreDocumentData<Session>(
    ['session', id],
    doc(db, `sessions/${id}`) as DocumentReference<Session>,
    { subscribe: true },
    { enabled: !!id }
  )

  useEffect(() => {
    if (id) {
      refetch()
    }
  }, [id, refetch])
  const { data: player, isLoading: playerLoading } =
    useFirestoreDocumentData<Player>(
      ['players', session?.player || '__TEST__'],
      getPlayerDoc(session?.player || '__TEST__'),
      { subscribe: true },
      {
        enabled: !!session?.player,
      }
    )

  const { data: challenger, isLoading: challengerLoading } =
    useFirestoreDocumentData<Player>(
      ['players', session?.challenger || '__TEST__'],
      getPlayerDoc(session?.challenger || '__TEST__'),
      { subscribe: true },
      { enabled: !!session?.challenger }
    )

  return { session, challenger, challengerLoading, playerLoading, player }
}

const PlayerActionButton: FC<{ standalone?: boolean }> = ({ standalone }) => {
  const { account, selectedSession } = useApp()
  const toast = useToast()
  const select = selectedSession!.select
  const { session, id, challenger, player } = useSession()
  const { currentSession } = player || {}
  const isMounted = useIsMounted()

  const isPlayer = useMemo(
    () => account?.id && session?.player === account?.id,
    [account, session]
  )
  const [isLoading, setIsLoading] = useState(false)
  const { status } = session || {}
  const { onClick, text } = useMemo(() => {
    if (!isPlayer || !id || !session) return { text: '' }
    switch (status) {
      case SessionStatus.IN_QUEUE:
        if (currentSession) return { text: '' }
        return {
          text: 'START',
          onClick: async () => {
            setIsLoading(true)
            try {
              await startSession({ sessionId: id! })
            } catch (e: any) {
              toast({ description: e.message })
            }
            setIsLoading(false)
          },
        }
      case SessionStatus.IN_PROGRESS:
        return {
          text: 'DONE',
          onClick: async () => {
            setIsLoading(true)
            await endSession({ sessionId: id! })
            isMounted.current && setIsLoading(false)
          },
        }
      default:
        if (!standalone) return { text: '' }
        return {
          text: 'VIEW',
          onClick: () => select(id),
        }
    }
  }, [
    session,
    status,
    id,
    select,
    isPlayer,
    currentSession,
    toast,
    isMounted,
    standalone,
  ])
  
  return (
    <ExpandOnMount in={!!onClick}>
      <Center mt={4} flexFlow='column'>
        <Button
          isLoading={isLoading}
          variant='outline'
          w='auto'
          minW='0'
          m={0}
          onClick={onClick}
          size='xs'
        >
          {text}
        </Button>
        <ExpandOnMount in={session?.status === SessionStatus.IN_PROGRESS}>
          <Text mt={2} fontSize='xs'>
            {challenger?.connectCode || ''}
          </Text>
        </ExpandOnMount>
      </Center>
    </ExpandOnMount>
  )
}

const ChallengerStatus = () => {
  const { session, player } = useSession()
  const { account } = useApp()
  const isChallenger = useMemo(
    () =>
      account?.id &&
      session &&
      session.challenger === account?.id &&
      session.player !== account?.id,
    [account, session]
  )
  return (
    <ExpandOnMount in={!!(session && isChallenger && session.status < 2)}>
      <Center flexFlow='column' mt={2}>
        <Text fontSize='xs'>
          {session?.status === SessionStatus.IN_QUEUE ? 'WAITING' : 'READY'}
        </Text>
        {session?.status === SessionStatus.IN_PROGRESS ? (
          <ExpandOnMount>
            <Text fontSize='xs'>{player?.connectCode || ''}</Text>
          </ExpandOnMount>
        ) : null}
      </Center>
    </ExpandOnMount>
  )
}

export const SessionPreview: React.FC<{
  id?: string
  canOpenPlayers?: boolean
  standalone?: boolean
  flexProps?: FlexProps
}> = ({ canOpenPlayers, id, flexProps, standalone }) => {
  const { selectedSession } = useApp()
  const select = selectedSession?.select
  const data = useSessionState(id)
  const { session } = data
  const { challenger, player, challengerLoading, playerLoading } = data

  const statusColor = useMotionValue('#ababab')
  useEffect(() => {
    if (session?.status === SessionStatus.IN_PROGRESS) {
      const anim = animate(statusColor, colors.lightblue, {
        damping: 0,
        mass: 7,
        type: 'spring',
      })

      return () => {
        anim.stop()
        animate(statusColor, '#ababab')
      }
    } else {
      animate(statusColor, '#ababab')
    }
    return () => {}
  }, [statusColor, session])

  const loading = challengerLoading || playerLoading
  const canClick = !!(id && standalone && select)
  return (
    <SessionContext.Provider value={{ id, ...data }}>
      <Flex
        onClick={canClick ? () => select(id) : undefined}
        cursor={canClick ? 'pointer' : 'inherit'}
        w='100%'
        p={2}
        bg={canClick ? 'blackAlpha.500' : undefined}
        _focus={canClick ? { background: 'rgba(0,0,0,0.3)' } : undefined}
        _active={canClick ? { background: 'rgba(0,0,0,0.4)' } : undefined}
        _hover={canClick ? { background: 'rgba(0,0,0,0.3)' } : undefined}
        height='auto'
        // minH='120px'
        borderRadius={6}
        position='relative'
        direction='column'
        {...flexProps}
      >
        <ExpandOnMount in={!!(challenger && player)}>
          <Flex justify='space-between'>
            <VStack p={1} flex={1} align='flex-start'>
              <PlayerPreview id={session?.player} size={80} />
              <GlowText color={colors.lightblue}>{player?.tag}</GlowText>
            </VStack>
            <Center
              py={4}
              overflow='hidden'
              position='relative'
              flexFlow='column'
              justifyContent='center'
              flex={1}
            >
              <Center flexFlow='column'>
                <Text fontSize='sm' whiteSpace='nowrap' fontWeight={600}>
                  {session?.format?.name || ''}
                </Text>
                <MotionText
                  style={{ color: statusColor }}
                  whiteSpace='nowrap'
                  opacity={0.9}
                  fontSize='xs'
                  fontWeight={600}
                >
                  {session
                    ? sessionStatusText[session.status]?.toUpperCase() || ''
                    : ''}
                </MotionText>
              </Center>

              <Flex minH={2} flexFlow='column'>
                <PlayerActionButton standalone={standalone} />
                <ChallengerStatus />
              </Flex>
            </Center>
            <VStack p={1} flex={1} align='flex-end'>
              <PlayerPreview id={session?.challenger} size={80} />
              <GlowText color={colors.lightblue}>
                {challenger?.tag || ''}
              </GlowText>
            </VStack>
          </Flex>
        </ExpandOnMount>
        <LoadingOverlay isLoading={loading} />
      </Flex>
    </SessionContext.Provider>
  )
}
