import { useFirestoreQuery } from "@react-query-firebase/firestore";
import { SessionStatus } from "@testme/shared";
import { limit, query, where } from "firebase/firestore";
import { useMemo } from "react";
import { sessionsCollection } from "../api/session";
import { firstDocSelector } from "../api/utils";
import { useApp } from "./useApp";

// Users can only have a single active subscription
const getMyCurrentGameQuery = (id: string) => query(
  sessionsCollection,
  where("challenger", "==", id),
  where("status", "==", SessionStatus.IN_PROGRESS),
  limit(1)
);

// Override the return type generic:
export const useMyCurrentGame = () => {
  const {account} = useApp()
  const uid = account?.id
  const currentGameQuery = useMemo(() => getMyCurrentGameQuery(uid || '__TEST__'), [uid])
return useFirestoreQuery(
  "myCurrentSession",
  currentGameQuery,
  {
    subscribe: true,
  },
  {
    enabled: !!uid,
    select: firstDocSelector
  }
);
} 