import { FieldMap, processData } from '@testme/shared'
import { uploadFile } from '../../api/db'
import { editPlayer, useMyPlayer } from '../../api/players'
import { useApp } from '../../hooks'
import { FormModal } from '../shared/FormModal'

const playerField: FieldMap = {
  name: 'Player',
  children: {
    image: {
      _type: 'file',
      getPath: ({ uid }) => `players/${uid}/profile-image`,
      accept: ['image'],
      label: 'Avatar',
      optional: true,
    },
    tag: {
      placeholder: 'Tag',
      _type: 'text',
    },
    fname: {
      placeholder: 'First Name',
      _type: 'text',
      optional: true,
    },
    lname: {
      placeholder: 'Last Name',
      _type: 'text',
      optional: true,
    },
    bio: {
      _type: 'textarea',
      placeholder: 'Bio..',
      optional: true,
    },
    connectCode: {
      _type: 'text',
      placeholder: 'Slippi Connect Code (only released to opponents)',
      optional: true,
    },
    topPlayerOptIn: {
      _type: 'boolean',
      label: 'I want to sign up as a top player'
    }
  },
}

export const EditPlayerModal = () => {
  const { modals, account } = useApp()
  const authUser = account?.auth?.data
  const { isOpen, onClose } = modals!.editPlayer
  const { player } = useMyPlayer()

  return (
    <FormModal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      field={playerField}
      onSubmit={(data) => processData(authUser, playerField, uploadFile, data)
        .then(d => editPlayer({data: d, id: authUser?.uid || ''}).then((res) => {
        modals?.editPlayer.onClose()
        return res
      }))}
      // onSubmit={(data) => processData(authUser, playerField, uploadFile, data).then((res) => {
      //   console.log(res)
      //   return res
      // })}
      value={player}
    />
  )
}
