import { Center, Image, ImageProps } from "@chakra-ui/react"
import { useEffect, useRef, useState } from "react"
import { LoadingOverlay } from "./LoadingOverlay"


export const ImageLoader = (props: ImageProps) => {
  const [isLoaded, set] = useState(false)
  const imageRef = useRef<HTMLImageElement>(null)
  useEffect(() => {
    set(!!(imageRef.current?.complete))
  }, [props.src])

  return(
    <Center position='relative' w='100%' h='100%'>
   <Image 
    ref={imageRef}
    w='100%' 
    h='100%' 
    onLoad={() => set(true)}
    transition='opacity 500ms'
    objectFit='cover' 
    opacity={isLoaded ? 1 : 0}
    {...props}
    />
    <LoadingOverlay isLoading={!isLoaded}/>
    </Center>
  )
}