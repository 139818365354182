import { Flex, FlexProps } from '@chakra-ui/react'
import { FC, useMemo } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeGrid, GridChildComponentProps } from 'react-window'
import { usePlayers } from '../../api/players'
import { PlayerPreview } from './PlayerPreview'

const itemSize = 230

const Cell: FC<GridChildComponentProps> = ({
  columnIndex,
  rowIndex,
  data,
  style,
}) => {
  const { players, columnCount, cellSize } = data
  const id = players[rowIndex * columnCount + columnIndex]
  if (!id) return null
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...style,
      }}
    >
      <PlayerPreview id={id} size={cellSize - 10}/>
    </div>
  )
}

// const getGridProps = (size)
export const TopPlayersGrid = (size: { width: number; height: number }) => {
  const { data: players } = usePlayers()
  const { columnCount, cellSize, height, rowCount, width } = useMemo(() => {
    const { width: gridWidth = 0, height: gridHeight = 0 } = size || {}
    const mobileView = gridWidth < 400
    const cCount = mobileView ? 2 : Math.floor(gridWidth / itemSize)
    const gSize = gridWidth / cCount
    const rCount = Math.ceil((players?.length || 0) / cCount)
    const h = Math.min(gridHeight, rCount * gSize)
    return {
      width: gridWidth,
      cellSize: gSize,
      height: h,
      columnCount: cCount,
      rowCount: rCount,
      mobileView,
    }
  }, [size, players])
  return (
    <FixedSizeGrid
      itemKey={({ data, columnIndex, rowIndex }) => {
        const index = rowIndex * columnCount + columnIndex
        return `${data.players[index]?.id || index}`
      }}
      width={width}
      height={height}
      itemData={{ players, columnCount, cellSize }}
      columnCount={columnCount}
      rowCount={rowCount}
      columnWidth={cellSize}
      rowHeight={cellSize}
    >
      {Cell}
    </FixedSizeGrid>
  )
}
export const TopPlayers: FC<{ height: FlexProps['height'] }> = ({ height }) => {
  return (
    <Flex
      p={2}
      borderRadius={6}
      width='100%'
      bg='blackAlpha.800'
      boxShadow={`0 0 10px rgba(255,255,255,0.5)`}
      direction='column'
      height={height}
    >
      <AutoSizer>
        {({ width, height }) => (
          <TopPlayersGrid height={height} width={width} />
        )}
      </AutoSizer>
    </Flex>
  )
}
