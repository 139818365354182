import {
  Flex, Modal,
  ModalBody,
  ModalContent,
  ModalOverlay, TabList,
  TabPanel,
  TabPanels, Tabs, VStack
} from '@chakra-ui/react'
import { getDateTimeString } from '@testme/shared'
import { useApp } from '../../hooks'
import { useIsInSession } from '../../hooks/isInSession'
import { useSessionPayment } from '../../hooks/useSessionPayment'
import { PaymentView } from '../payments/PaymentView'
import { ExpandOnMount } from '../shared/ExpandOnMount'
import { SolidTab, StatusText } from '../shared/Text'
import { SessionChat } from './Chat'
import { PlayerReport } from './PlayerReport'
import { SessionPreview } from './SessionPreview'

const SessionPaymentInfo = () => {
  const { account, selectedSession } = useApp()
  const { id: uid } = account || {}
  const { data: session, id } = selectedSession || {}
  const isInSession = useIsInSession(session, uid)
  const { data: payment } = useSessionPayment(
    isInSession ? id || undefined : undefined
  )
  return (
    <ExpandOnMount in={isInSession}>
      <Flex px={1} w='100%' bg='blackAlpha.300' borderRadius={6}>
        <PaymentView payment={payment?.data() || undefined} />
      </Flex>
    </ExpandOnMount>
  )
}

const SessionInfo = () => {
  const { selectedSession } = useApp()
  const { startedOn, createdOn, completedOn } = selectedSession?.data || {}
  return (
    <VStack w='100%' px={3} spacing={0}>
      <VStack
        w='100%'
        minH='400px'
        align='flex-start'
        position='relative'
        spacing={0}
        p={2}
        px={2}
        borderRadius={6}
        background='blackAlpha.300'
      >
        <VStack pb={2} spacing={0} align='flex-start'>
          <StatusText>
            {createdOn ? `Queue joined on ${getDateTimeString(createdOn)}` : ''}
          </StatusText>
          {startedOn ? (
            <StatusText>Started on {getDateTimeString(startedOn)}</StatusText>
          ) : null}
          {completedOn ? (
            <StatusText>
              Completed on {getDateTimeString(completedOn)}
            </StatusText>
          ) : null}
        </VStack>
        <SessionPaymentInfo />
          <PlayerReport/>
      </VStack>
    </VStack>
  )
}

const SessionBody = () => {
  const { account } = useApp()
  const uid = account?.id
  const { selectedSession } = useApp()
  const { data: session } = selectedSession || {}
  const isInSession = useIsInSession(session, uid)
  return (
    <Tabs p={3} variant='solid' width='100%'>
      {isInSession ? (
        <TabList>
          <SolidTab borderTopLeftRadius={6}>CHAT</SolidTab>
          <SolidTab borderTopRightRadius={6}>INFO</SolidTab>
        </TabList>
      ) : null}
      <TabPanels
        flex={1}
        borderRightRadius={6}
        borderBottomLeftRadius={6}
        background='whiteAlpha.300'
      >
        {isInSession ? (
          <TabPanel p={0} pt={5} pb={0}>
            <SessionChat />
          </TabPanel>
        ) : null}

        <TabPanel p={0} py={3}>
          <SessionInfo />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
export const SessionFullViewModal = () => {
  const { selectedSession } = useApp()
  const { data: session, deselect } = selectedSession || {}
  return (
    <Modal isCentered isOpen={!!session} onClose={deselect!}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={0} h='500px'>
          <ExpandOnMount in={!!session}>
            <VStack spacing={0}>
              <SessionPreview
                canOpenPlayers
                id={selectedSession?.id || undefined}
              />
              <SessionBody />
            </VStack>
          </ExpandOnMount>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
