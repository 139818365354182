import {
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalProps,
  Text, useToast
} from '@chakra-ui/react'
import { useCallback, useMemo, useState } from 'react'
import { getStripeAccountLink } from '../../../../api/functions'
// import {
//   getStripeAccountLink
// } from '../../../../api/functions'
import { useMyUser } from '../../../../api/users'
import stripeLogo from '../../../../assets/images/stripe-white.svg'
import { GhostButton } from '../../../shared/Buttons'
import { Step } from '../Step'


export const PayoutsModal = (props: Pick<ModalProps, 'onClose' | 'isOpen'>) => {
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useMyUser()
  const accountId = user?.stripe?.accountId
  // const chargesEnabled = user?.stripe?.chargesEnabled

  const text = useMemo(() => {
    if(!accountId) return 'Connect to Stripe to accept payments'
    // if(!chargesEnabled) return 'Stripe connect incomplete'
    return 'Connected to stripe!'
  }, [accountId])

  const handleStripeConnect = useCallback(() => {
    setIsLoading(true)
    getStripeAccountLink({capabilities: ['transfers']}).then(({data}) => {
      const {url} = data || {}
      setIsLoading(false)
      // new tab for dashboard, redirect for sign on 
      if(accountId) {
        window.open(url, '_blank')
      } else {
      window.location.href = url;
      }
    }).catch(err => {
      setIsLoading(false)
      console.error(err)
      toast({status: 'error', description: err.message})
    })
  }, [accountId, toast])
  return (
    <Modal {...props} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody color='white' py={4}>
          <Flex>

          <Text mt='3px' fontSize='1.2rem'>PAYOUTS</Text>
          </Flex>
          <Step isComplete={!!accountId}>
            {text}
          </Step>
          <GhostButton 
            isLoading={isLoading} 
            onClick={handleStripeConnect} 
            fontWeight={600} 
            mx={0} 
            mt={4}
            >
              {accountId ? '' : 'CONNECT TO'}
          <Image  h='36px' src={stripeLogo} />
          {accountId ? 'DASHBOARD' : ''}
          </GhostButton>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

