
import { motion, Variants } from "framer-motion";
import { FC } from "react";

const variants: Variants = {
  initial: {
    scale: 0,
    y: 0,
    opacity: 1
  },
  animate: (index: number) => ({
    scale: 1,
    y: 10,
    opacity: 0,
    transition: {
      duration: 3,
      delay: index ,
      repeat: Infinity
    }
  })
};

const Arrow: FC<{index: number}> = ({index}) => {
  return(
            <motion.svg
              variants={variants}
              initial="initial"
              animate="animate"
              custom={index}
              viewBox='0 0 100 50'
              style={{
                height: 50,
                width: 100,
                position: "absolute"
              }}
            >
              <path
                d='M 15 15 L 50 35 L 85 15'
                stroke='white'
                strokeWidth='4'
                fill='none'
              />
            </motion.svg>
  )
}

export const DownArrow = () => {
  return (
    <motion.div
      style={{
        height: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 30,
        position: "relative"
      }}
    >
      {Array(3)
        .fill(null)
        .map((_, index) => <Arrow key={index} index={index}/>)}
    </motion.div>
  );
};
