import { FC } from 'react'
import { Field } from 'react-final-form'
import { ExpandOnMount } from '../shared/ExpandOnMount'

export const Condition: FC<{ when: string; is: any }> = ({
  when,
  is,
  children,
}) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (
      <ExpandOnMount in={value === is}>{children}</ExpandOnMount>
    )}
  </Field>
)
