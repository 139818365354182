import {
  Center, Flex, Image
} from '@chakra-ui/react'
import { useMemo } from 'react'
import logo from '../assets/images/logo.svg'
import { Dashboard } from '../components/dashboard'
import { Intro } from '../components/home/Intro'
import { PlayerFullViewModal } from '../components/players/PlayerFullViewModal'
import { SessionFullViewModal } from '../components/sessions/SessionFullViewModal'
import { useApp } from '../hooks'


export const Home = () => {
  const { entered, enter, screen, account } = useApp()
  const authUser = account?.auth?.data
  const {imageHeight, splashHeight} = useMemo(() => {
    const iHeight = (screen?.height || 1000)/(entered ? 4 : 2)
    const sHeight = (screen?.height || 1000) - iHeight
    return {imageHeight: iHeight, splashHeight: sHeight}
  }, [screen, entered])
  return (
    <Flex
      position='relative'
      flexFlow='column'
      align='center'
      w='100%'
      h='100%'
    >
      <Flex  
      transition='opacity 1500ms ease 500ms'
      opacity={entered ? 1 : 0}
      flexFlow='column' 
      align='center' 
      width={['90%', 450, 800]}
      >
        <Center 
        
      height={`${imageHeight}px`}
      transition='height 1000ms'
      transitionDelay='500ms'
        >
          <Image 
          transition={`height 500ms ease ${authUser ? 500 : 0}ms`}
          onLoad={enter} 
          h='50%' 
          src={logo} 
          />
        </Center>
        {authUser ?
        <Dashboard/> : <Intro splashHeight={splashHeight}/>}
      </Flex>
      <PlayerFullViewModal/>
      <SessionFullViewModal/>
    </Flex>
  )
}
