import { Flex } from '@chakra-ui/react'
import { useApp } from '../../../hooks'
import { TopPlayers } from '../../players/TopPlayers'

export const ScrubPanel = () => {
  const {screen} = useApp()
  return (
    <Flex w='100%' flexFlow='row wrap' justify='space-evenly' flex={1}>
      <TopPlayers height={screen?.height || 0}/>
    </Flex>
  )
}
