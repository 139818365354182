import { Box } from '@chakra-ui/react'
import {
  Elements, PaymentElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { FC, useCallback, useState } from 'react'
import { STRIPE_PK } from '../../constants'
import { GhostButton } from '../shared/Buttons'

const stripePromise = loadStripe(
  STRIPE_PK
)

type OnResponse = (res: { error?: string; success?: string }) => void
const CheckoutBody = ({
  onResponse,
}: {
  onResponse: OnResponse
}) => {
  const stripe = useStripe()
  const elements = useElements()
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault()
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }
    setIsLoading(true)
    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: 'https://testme.gg',
      },
      redirect: 'if_required',
    })
    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      onResponse({ error: result.error.message })
      console.log(result.error.message)
    } else {
      onResponse({ success: 'success!' })
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
    setIsLoading(false)
  }, [stripe, elements, onResponse])

  return (
    <Box px={1} w='100%'>
      <form>
        <PaymentElement options={{business: {name: 'testme.gg'}}} />
        <GhostButton
          isLoading={isLoading}
          mt={6}
          opacity={stripe ? 1 : 0}
          mx='auto'
          onClick={handleSubmit}
        >
          CHECKOUT
        </GhostButton>
      </form>
    </Box>
  )
}
export const Checkout: FC<{ clientSecret: string; onResponse: OnResponse }> = ({
  clientSecret,
  onResponse,
}) => {
  return (
    <Elements
      options={{ clientSecret, appearance: { theme: 'night' } }}
      stripe={stripePromise}
    >
      <CheckoutBody
        onResponse={onResponse}
      />
    </Elements>
  )
}
