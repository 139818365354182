import { SyncFunction } from "./utils"

export enum SessionStatus {
  PAYMENT_FAILED = -2,
  PAYMENT_PROCESSING = -1,
  IN_QUEUE = 0,
  IN_PROGRESS = 1,
  COMPLETE = 2,
}

export type SessionReport = {
  createdOn: number
  reportType: 'cancel-by-player' | 'other'
  details?: string
}
export interface Session {
  player: string
  challenger: string
  chatId: string
  status: SessionStatus
  format: SessionFormat | null
  createdOn: number
  startedOn?: number
  completedOn?: number
  reports?: Array<SessionReport>
}

export interface SessionFormat {
  id: string
  name: string
  rate: number
}



export interface AddPlayerArgs {
  playerId: string
  gameId: string
}

export type StartSessionArgs = {
  sessionId: string
}

export type EndSessionArgs = StartSessionArgs
export type SubmitSessionReportArgs = {
  sessionId: string
  details?: string
  reportType: string
}

export type StartSession = SyncFunction<StartSessionArgs, void>
export type EndSession = SyncFunction<EndSessionArgs, void>
export type SubmitSessionReport = SyncFunction<SubmitSessionReportArgs, void>
