import { Text, VStack } from '@chakra-ui/react'
import { useFirestoreQuery } from '@react-query-firebase/firestore'
import { Session } from '@testme/shared'
import { FC, useMemo } from 'react'
import { getPlayerPastGamesQuery } from '../../api/session'
import { SessionPreview } from '../sessions/SessionPreview'
import { LoadingOverlay } from '../shared/LoadingOverlay'

const usePlayerPastGames = (id?: string) => {
  const query = useMemo(() => getPlayerPastGamesQuery(id), [id])
  const { data: fetched, ...stuff } = useFirestoreQuery<Session>(
    ['pastGames', id],
    query,
    { subscribe: true },
    { enabled: !!id }
  )
  const data = useMemo(() => fetched?.docs?.map((f) => ({...f.data(), id: f.id})), [fetched])
  return {data, ...stuff}
}

export const PlayerPastGames: FC<{ id?: string }> = ({ id }) => {

  const {data, isLoading} = usePlayerPastGames(id)
  return (
    <VStack position='relative' height='400px' overflowY='auto' p={2} w='100%'>
      {data ? (
        data.map((d, index) => (
            <SessionPreview standalone id={d.id} key={index} />
        ))
      ) : (
        <Text p={2}>No past games... yet</Text>
      )}
      <LoadingOverlay isLoading={isLoading}/>
    </VStack>

  )
}
