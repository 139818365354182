import {
  Collapse, Flex, IconButton, Image, Modal, ModalBody, ModalContent, ModalOverlay, StackDivider, Text, Tooltip, useDisclosure, VStack
} from '@chakra-ui/react'
import { FieldMap, getDateTimeString } from '@testme/shared'
import { useMemo } from 'react'
import { submitSessionReport } from '../../api/session'
import reportIcon from '../../assets/images/report.svg'
import { useApp } from '../../hooks'
import { useTimeout } from '../../hooks/useTimeout'
import { FieldMapForm } from '../forms/Form'

const reportField: FieldMap = {
  children: {
    reportType: {
      _type: 'select',
      placeholder: 'Report Type',
      options: [
        { id: 'cancel-by-player', text: "I can't make it (refund challenger)" },
        { id: 'other', text: 'Something else (no refund)' },
      ],
    },
    notes: {
      _type: 'textarea',
      placeholder: 'Details...',
    },
  },
}


export const PlayerReport = () => {
  const { selectedSession, account } = useApp()
  const disclosure = useDisclosure()
  const uid = account?.id
  const sessionId = selectedSession?.id
  const session = selectedSession?.data
  const reports = session?.reports
  const [mounting] = useTimeout(true, 1000)
  const isPlayer = useMemo(() => !!uid && uid === session?.player, [uid, session])
  return (
    <Collapse style={{width: '100%'}} in={isPlayer && !mounting}>
   <Flex
      mt={2}
      w='100%'
      position='relative'
      opacity={!mounting && isPlayer ? 1 : 0}
      transition='opacity 500ms'
      pointerEvents={isPlayer ? 'auto' : 'none'}
      p={2}
      background='blackAlpha.300'
      borderRadius={6}
      align='center'
    >
      <Tooltip
        color='white'
        bg='red.600'
        placement='top'
        label='Report an issue with this session'
      >
        <IconButton
          onClick={disclosure.onOpen}
          // variant='ghost'
          background='red.600'
          position='absolute'
          size='sm'
          top={2}
          right={2}
          h={8}
          w={8}
          style={{ padding: 0 }}
          aria-label='report'
          icon={
            <Image transform={'scale(-1, 1)'} height='20px' src={reportIcon} />
          }
        />
      </Tooltip>
      <VStack w='100%' spacing={0}>
        <Flex w='100%' pl={3} minH='35px' align='center'>

        <Text color='red.400' fontWeight={600}>
          Reports
        </Text>

        </Flex>
          <VStack divider={<StackDivider opacity={0.3}/>} px={3} w='100%'>
            {reports && reports.map(r => (
            <VStack key={`${r.createdOn}`} align='flex-start' w='100%' spacing={0}>
              <Text>{getDateTimeString(r.createdOn)}</Text>
              <Text>{r.reportType === 'cancel-by-player' ? 'Refunded' : 'Not refunded'}</Text>
              <Text>{r.details || 'No details'}</Text>
            </VStack>
            ))}
          </VStack>
      </VStack>
      <Modal isCentered {...disclosure}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <FieldMapForm
              onSubmit={async (d, form) => {
                const res = await submitSessionReport({ ...d, sessionId, details: d.details || '' })
                form.reset({})
                disclosure.onClose()
                return res
              }}
              field={reportField}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
    </Collapse>
 
  )
}
