import { useFirestoreDocument, useFirestoreDocumentData } from "@react-query-firebase/firestore"
import { User } from "@testme/shared"
import { User as FirebaseUser } from "firebase/auth"
import { collection, doc, DocumentReference } from "firebase/firestore"
import { useMemo } from "react"
import { useApp } from "../hooks"
import { db } from "./db"
export const users = collection(db, 'players')
export const getMyUserDoc = (authUser?: FirebaseUser | null) => doc(db, `users/${authUser?.uid || 'null'}`)
export const useMyUser = () => {
  const { account} = useApp()
  const authUser = account?.auth?.data
  const {data: user, isLoading} = useFirestoreDocumentData<User>(
    [users, account?.id || '__TEST__'], 
    doc(db, `users/${authUser?.uid || 'null'}`) as DocumentReference<User>,
    {subscribe: true},
    {enabled: !!authUser}
    )

  return {user, isLoading}
}