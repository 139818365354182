import useResizeObserver from "@react-hook/resize-observer"
import { useState, useLayoutEffect } from "react"

export const useSize = (target: React.RefObject<HTMLDivElement>) => {
  const [size, setSize] = useState<Omit<DOMRect, 'toJSON'>>({bottom: 0, height: 0, left: 0, right: 0, top: 0, width: 0, x: 0, y: 0})

  useLayoutEffect(() => {
    if(!target.current) return
    setSize(target.current.getBoundingClientRect())
  }, [target])

  // Where the magic happens
  useResizeObserver(target, (entry) => setSize(entry.contentRect))
  return size
}