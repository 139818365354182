import { LinkIcon } from '@chakra-ui/icons'
import { IconButton, Text, Tooltip } from '@chakra-ui/react'
import { FC } from 'react'
import { useTimeout } from '../../hooks/useTimeout'
import { copyTextToClipboard } from '../../utils/io'

export const Copy: FC<{ text?: string }> = ({ children, text }) => {
  const [copied, setCopied] = useTimeout()
  return (
    
    <Text fontSize='sm' mt={2} color='whiteAlpha.900'>
      {children}
      <Tooltip placement='top' isOpen={!!copied} label='Copied!'>

      <IconButton
        ml={2}
        size='sm'
        variant='ghost'
        _focus={{ boxShadow: 'none' }}
        onClick={async () => {
          await copyTextToClipboard(text || '')
          setCopied(true)
        } }
        aria-label='copy'
        icon={<LinkIcon />}
      />
      </Tooltip>
    </Text>
  )
}
