import React, { useEffect, useState } from "react"

// if value is defined, set to undefined after duration
export const useTimeout = (
  value?: any, 
  duration: number = 1500
  ): [any, React.Dispatch<any>] => {

  const [val, set] = useState(value)
  useEffect(() => {
    if(val){
      const t = setTimeout(() => {
        set(undefined)
      }, duration)
      return () => {clearTimeout(t)}
    }
    return () => {}
  }, [val, duration])

  return [val, set]
}