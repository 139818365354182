import {
  getDatabase, onDisconnect, onValue, ref, serverTimestamp as rtTimestamp,
  set
} from 'firebase/database'
import { doc, serverTimestamp, updateDoc } from 'firebase/firestore'
import { useEffect } from 'react'
import { db } from '../api/db'
import { app } from '../api/initialize'
const realtimeDB = getDatabase(app)

export const useActiveStatus = (uid?: string) => {
  // Since I can connect from multiple devices or browser tabs, we store each connection instance separately
  // any time that connectionsRef's value is null (i.e. has no children) I am offline
  useEffect(() => {
    if (!uid) return () => {}

    // We'll create two constants which we will write to
    // the Realtime database when this device is offline
    // or online.
    var isOfflineForDatabase = {
      state: 'offline',
      last_changed: rtTimestamp(),
    }

    var isOnlineForDatabase = {
      state: 'online',
      last_changed: rtTimestamp(),
    }

    const myPlayerRef = doc(db, `players/${uid}`)
    const realtimeRef = ref(realtimeDB, '/status/' + uid)
    var isOfflineForFirestore = {
      state: 'offline',
      last_changed: serverTimestamp(),
    }

    var isOnlineForFirestore = {
      state: 'online',
      last_changed: serverTimestamp(),
    }
    onValue(ref(realtimeDB, '.info/connected'), function (snapshot) {
      if (snapshot.val() === false) {
        // Instead of simply returning, we'll also set Firestore's state
        // to 'offline'. This ensures that our Firestore cache is aware
        // of the switch to 'offline.'
        updateDoc(myPlayerRef, {activity: isOfflineForFirestore})
        return
      }

      onDisconnect(realtimeRef)
        .set(isOfflineForDatabase)
        .then(function () {
          set(realtimeRef, isOnlineForDatabase)

          // We'll also add Firestore set here for when we come online.
          updateDoc(myPlayerRef, {activity: isOnlineForFirestore})
        })
    })
    return () => {
      try {
      set(realtimeRef, isOfflineForDatabase)
      updateDoc(myPlayerRef, {activity: isOfflineForFirestore})
      } catch(err) {
        console.error(err)
      }
    }
  }, [uid])
}
