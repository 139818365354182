import { AnyFunction,  EndSession, GetPaymentClientSecret, SubmitSessionReport, Session, StartSession } from '@testme/shared'
import { collection, CollectionReference, limit, orderBy, query, where } from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { db } from './db'
import { app } from './initialize'

const functions = getFunctions(app)
export const sessionsCollection = collection(db, 'sessions') as CollectionReference<Session>
export const getPlayerQueueQuery = (id?: string) => query<Session>(
  sessionsCollection, 
  where('player', '==', id || '__TEST__'),
  where('status', '>', -1),
  where('status', '<', 2),
  orderBy('status', 'desc'),
  orderBy('createdOn'),
  limit(20),
  ) 

export const getPlayerPastGamesQuery = (id?: string) => query<Session>(
  sessionsCollection, 
  where('player', '==', id || '__TEST__'),
  where('status', '>', 1),
  orderBy('status'),
  orderBy('createdOn', 'desc'),
  limit(20),
)


// includes our types in firebase functions
const getFunction = <F extends AnyFunction>(name: string) =>
  httpsCallable<Parameters<F>[0], ReturnType<F>>(functions, name)

export const getPaymentClientSecret = getFunction<GetPaymentClientSecret>('getPaymentClientSecret')
export const startSession = getFunction<StartSession>('startSession')
export const endSession = getFunction<EndSession>('endSession')
export const submitSessionReport = getFunction<SubmitSessionReport>('submitSessionReport')
