import { VStack } from '@chakra-ui/react'
import { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useMyPlayer } from '../../../../api/players'
import { useMyUser } from '../../../../api/users'
import { useApp } from '../../../../hooks'
import { GhostButton } from '../../../shared/Buttons'
import { GlowText } from '../../../shared/Text'
import { Step } from '../Step'
import { PayoutsModal } from './PayoutsModal'
import { SessionFormatsModal } from './SessionFormatsModal'
import { VerificationModal } from './VerificationModal'



export const TopPlayerStatus = () => {
  const { modals, account, selectedPlayer } = useApp()
  const uid = account?.id
  const {verification, payouts, sessionFormats} = modals!
  const { player } = useMyPlayer()
  const { user } = useMyUser()
  const {pathname} = useLocation()
  useEffect(() => {
    if(pathname === '/stripe-return'){
      payouts.onOpen()
    }
  }, [])
  const formatsEntered = useMemo(() => 
    !!player?.sessionFormats?.length
  , [player])
  return (
    <VStack height='100%' align='flex-start' flex={1}>
      <GlowText>SIGN UP STATUS</GlowText>
      <Step onClick={modals?.editPlayer.onOpen} isComplete={!!player}>
        Player Info
      </Step>
      <Step 
        onClick={verification.onOpen} 
        isComplete={!!player?.isVerified}
        >
        Verification
      </Step>
      <Step 
      onClick={payouts.onOpen} 
      isComplete={!!user?.stripe?.accountId}
      >Payout Method
      </Step>
      <Step 
      onClick={sessionFormats.onOpen} 
      isComplete={formatsEntered}
      >
        Set your rates
      </Step>
      <VStack w='100%' justify='flex-end' flex={1} p={1} >
      <GhostButton onClick={() => uid && selectedPlayer?.select(uid)}>VIEW QUEUE</GhostButton>
      </VStack>
      <VerificationModal {...verification} />
      <PayoutsModal {...payouts}/>
      <SessionFormatsModal {...sessionFormats}/>
    </VStack>
  )
}
