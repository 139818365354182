import { Box, Collapse, CollapseProps, useTimeout } from "@chakra-ui/react";
import { FC, useState } from "react";


export const ExpandOnMount:FC<CollapseProps> = ({in: isIn = true, children, ...props}) => {
  const[mounted, set] = useState(false)
  useTimeout(() => set(true), 133)
  return(
    <Collapse 
    {...props} 
    unmountOnExit
    in={mounted && isIn}
    style={{width: '100%', ...props?.style}} 
    >
      <Box transition='opacity 500ms' opacity={mounted && isIn ? 1 : 0}>
        {children}
      </Box>
    </Collapse>
  )
}