import { useAuthUser } from '@react-query-firebase/auth'
import { useFirestoreDocument, useFirestoreDocumentData } from '@react-query-firebase/firestore'
import { doc } from 'firebase/firestore'
import { useMemo } from 'react'
import { USERS } from '../../constants'
import { auth as appAuth } from '../../utils/auth'

export const useAccount = () => {
  const auth = useAuthUser(['user'], appAuth)
  const { data: authUser } = auth
  const ref = useMemo(
    () => doc(USERS, authUser?.uid || 'UNDEFINED'),
    [authUser]
  )
  const {data: user, ...data} = useFirestoreDocumentData(
    ['users', authUser?.uid || '__TEST__'],
    ref,
    {
      subscribe: true,
    },
    { enabled: !!authUser?.uid }
  )

  return { auth, user, id: authUser?.uid, data }
}

