import { Flex, Text } from "@chakra-ui/react";
import { SessionFormat } from "@testme/shared";
import { FC } from "react";
import { colors } from "../../constants";


export const FormatPreview: FC<SessionFormat> = (format) => {
  const {name, rate} = format
  return(
    <Flex px={5} py={3} bg='#172030' _hover={{bg:'#283854'}} w='100%' align='center'>
      <Text color='whiteAlpha.900' fontSize='1.2rem' mr={2}>{name}</Text>
      <Text fontSize='md' fontWeight={600} color={colors.lightblue}>${rate}</Text>
    </Flex>
  )
}