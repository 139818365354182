import { QuestionIcon } from '@chakra-ui/icons'
import {
  IconButton, Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  StackDivider, VStack
} from '@chakra-ui/react'
import { useApp } from '../hooks'
import { PayoutCalculator } from './help/PayoutCalculator'
import { GlowInfo, GlowText } from './shared/Text'

const faqItems = [
  {
    q: 'Where does my money go?',
    a: () => <VStack  spacing={4} align='flex-start' w='100%'>
      <GlowText whiteSpace='pre-wrap'>
{`- 90% goes to the player you practice against (-30¢ for stripe fee)
- 5% goes to me (Elan Canfield, website dev)
- 2.1% goes to Slippi devs
- 2.9% pays stripe fees
`}
      </GlowText>
      <PayoutCalculator/>
    </VStack>, 
  },
  {
    q: 'How are rates set?',
    a: () => <GlowText>Top players set their own rates.</GlowText>,
  },
  {
    q: 'Contact',
    a: () => <GlowText>If you have any questions you can reach me on Twitter @tofeezy</GlowText>
  }
]
export const Help = () => {
  const {modals} = useApp()
  const { isOpen, onClose, onOpen } = modals!.help!

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody py={4} px={6}>
            <VStack w='100%' divider={<StackDivider opacity={0.3} />}>
              {faqItems.map(({ q, a }) => (
                <VStack key={q} mb={2} w='100%' align='flex-start'>
                  <GlowInfo>{q}</GlowInfo>
                  {/* <GlowText fontSize='1.1rem' whiteSpace='pre-wrap'> */}
                    {a()}
                  {/* </GlowText> */}
                </VStack>
              ))}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <IconButton
        aria-label='help'
        // size='lg'
        icon={<QuestionIcon/>}
        p={0}
        onClick={onOpen}
        color='white'
        opacity={0.7}
        fontSize='2rem'
        variant='ghost'
        position='absolute'
        bottom='1rem'
        left='1rem'
      />
    </>
  )
}
