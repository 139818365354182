// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDOmh9D1D4cOKjJw-rHUZMCwvHDPxcsu-8",
  authDomain: "testme-gg.firebaseapp.com",
  projectId: "testme-gg",
  storageBucket: "testme-gg.appspot.com",
  messagingSenderId: "288740582895",
  appId: "1:288740582895:web:c5a4ffde9097080154c21a",
  measurementId: "G-FHFTM9H0Q3"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);