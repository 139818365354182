import { FieldMap, FileField, FileValue, processData } from '@testme/shared'
import { AnyObject, FORM_ERROR, ValidationErrors } from 'final-form'
import { User } from 'firebase/auth'
import { DocumentReference, getFirestore, setDoc } from 'firebase/firestore'
import { getDownloadURL, getStorage, ref, uploadString } from 'firebase/storage'
import { validateFieldMap } from '../components/forms/input/utils'
import { app } from './initialize'
export const db = getFirestore(app)
export const storage = getStorage(app)



export const uploadFile = async (user: User, field: FileField, data?: FileValue) => {
  if (data) {
    if (data.dataUrl) {
      const res = await uploadString(
        ref(storage, field.getPath(user)),
        data.dataUrl,
        'data_url'
      )
      const url = await getDownloadURL(res.ref)
      return { url, type: data.type }
    } else {
      return data
    }
  }
}
export const update = async (
  user: User | null | undefined,
  ref: DocumentReference,
  field: FieldMap,
  data: AnyObject
): Promise<ValidationErrors> => {
  if (!user) return { [FORM_ERROR]: 'Not logged in' }
  try {
    const errors = validateFieldMap(field, data)
    if (errors) return errors
    const withFileUrls = await processData(user, field, uploadFile, data)
    await setDoc(ref, withFileUrls, { merge: true })
    return undefined
  } catch (err) {
    console.error(err)
    return { [FORM_ERROR]: (err as any).message }
  }
}
