
import { FileValue } from './files'
import {ReactNode} from 'react'


export type FieldCondition = {
  when: string
  is: any
}
type BaseField = {
  optional?: boolean,
  condition?: FieldCondition
}
export type TextField = BaseField & {
  _type: 'text',
  type?: 'password' | 'email' | 'text' | 'phone',
  value?: string,
  placeholder: string,
  label?: ReactNode,
  validate?: (value?: string) => string | undefined
}

export type TextAreaField = BaseField & {
  _type: 'textarea',
  value?: string,
  placeholder: string,
  label?: ReactNode,
  validate?: (value?: string) => string | undefined
}

export type SelectField<T = any> = BaseField & {
  _type: 'select',
  options: Array<T>,
  label?: string,
  renderOption?: (opt: T) => ReactNode
  placeholder: string,
  value?: string
  validate?: (value?: string) => string | undefined
}

export type BooleanField = BaseField & {
  _type: 'boolean',
  type?: 'switch' | 'checkbox'
  label: string
  value?: boolean
  validate?: (value?: boolean) => string | undefined
}

export type FileField = BaseField & {
  _type: 'file',
  value?: FileValue,
  getPath: (user: any) => string,
  label: string,
  accept: Array<'image' | 'pdf'>
  validate?: (value?: FileValue) => string | undefined
}

export type CurrencyAmountField = BaseField & {
  _type: 'currency',
  value?: number,
  label?: string,
  placeholder?: string
  validate?: (value?: number) => string | undefined
}

export type RecordField = BaseField & {
  itemField: Field | FieldMap,
  value?: any
  label?: ReactNode
  validate?: (value?: Array<any>) => string | undefined
}

type AnyObject = {[key: string]: any}
export type Field = TextField | FileField | BooleanField | SelectField | TextAreaField | CurrencyAmountField
export type FieldType = Field['_type']
export type FieldMap = {
  name?: string,
  condition?: FieldCondition
  validate?: (v: any) => AnyObject | undefined,
  children:{[key: string]: IFormElement}
}

export type IFormElement = Field | FieldMap | RecordField
export const isField = (fieldOrFieldMap: IFormElement): fieldOrFieldMap is Field => !!(fieldOrFieldMap as Field)._type
export const isRecordField = (fieldOrFieldMap: IFormElement): fieldOrFieldMap is RecordField => !!(fieldOrFieldMap as RecordField).itemField
