import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalProps,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react'
import { RecordField } from '@testme/shared'
import { update } from '../../../../api/db'
import { getPlayerDoc, useMyPlayer } from '../../../../api/players'
import { useApp } from '../../../../hooks'
import { Form } from '../../../forms/Form'
import { PayoutCalculator } from '../../../help/PayoutCalculator'

const sessionFormatsField: RecordField = {
  label: <Text>Rates & Formats</Text>,
  itemField: {
    children: {
      name: {
        _type: 'text',
        placeholder: 'Name (Bo5, 3 Games, etc.)',
      },
      rate: {
        _type: 'currency',
        placeholder: 'Rate (USD)',
      },
    },
  },
}

export const SessionFormatsModal = (
  props: Pick<ModalProps, 'onClose' | 'isOpen'>
) => {
  const { player } = useMyPlayer()
  const { account } = useApp()
  const user = account?.auth?.data
  const handleSubmit = async (data: any) =>
    update(
      user,
      getPlayerDoc(user?.uid),
      { children: { sessionFormats: sessionFormatsField } },
      { sessionFormats: data }
    ).then(res => {
      if(!res) props.onClose()
      return res
    })

  return (
    <Modal isCentered {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={2}>
          <VStack w='100%' spacing={4} divider={<StackDivider opacity={0.3}/>} align='flex-start'>
            <Form
              field={sessionFormatsField}
              onSubmit={handleSubmit}
              value={player?.sessionFormats}
            />
            <PayoutCalculator/>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
