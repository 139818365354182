import { Button, Flex } from '@chakra-ui/react'
import { useFirestoreDocumentData } from '@react-query-firebase/firestore'
import { Player } from '@testme/shared'
import { getPlayerDoc } from '../../api/players'
import { colors } from '../../constants'
import { useApp } from '../../hooks'
import { ImageLoader } from '../shared/ImageLoader'
import { GlowText } from '../shared/Text'
import { OnlineIndicator } from './OnlineIndicator'

export const PlayerTag: React.FC<Player & {size?: 'lg' | 'sm'}> = ({size, ...player}) => (
  <GlowText
    lineHeight='20px'
    fontFamily='Russo One'
    fontSize={size === 'sm' ? '0.9rem' : '1.2rem'}
    color={colors.lightblue}
  >
    {player.tag?.toUpperCase() || 'NO TAG'}
  </GlowText>
)
export const PlayerPreview: React.FC<{
  id?: string,
  size?: number
}> = ({ size = 180, id }) => {
  const {data: player} = useFirestoreDocumentData<Player>(['players', id], getPlayerDoc(id), {subscribe: true,}, {enabled: !!id, })
  const { selectedPlayer } = useApp()
  const select = selectedPlayer?.select
  return (
    <Button
      display='flex'
      as={id ? undefined : Flex}
      alignItems='center'
      justifyContent='center'
      onClick={id && select ? () => select(id) : undefined}
      variant='unstyled'
      position='relative'
      overflow='hidden'
      _hover={{ boxShadow: id ? `0 0 8px ${colors.lightblue}` : undefined }}
      borderRadius={6}
      bg='whiteAlpha.300'
      height={`${size}px`}
      width={`${size}px`}
    >
      <Flex w='100%' h='100%' position='absolute' opacity={ size > 160 ? 0.5 : 1}>
        {player?.image?.url ? (
          <ImageLoader pointerEvents='none' src={player.image.url} zIndex={0} />
        ) : null}
      </Flex>

        <Flex
          w='100%'
          h='100%'
          p={2}
          direction='column'
          background='linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 40%, rgba(0,0,0,0.8) 100%)'
        > {
          size > 140 ?
          <Flex w='100%' justify='space-between'>
            <PlayerTag size={size > 170 ? 'lg' : 'sm'} {...player} />
            <OnlineIndicator player={player} />
          </Flex> : 
          null
        }

          {size > 170 && player?.fname && player?.lname ? (
            <GlowText fontSize='0.9rem' intensity={0.4} opacity={0.8} fontFamily='Russo One'>
              {player.fname.toUpperCase()} {player.lname.toUpperCase()}
            </GlowText>
          ) : null}
        </Flex>
    </Button>
  )
}
