import { Button, ButtonProps, forwardRef } from '@chakra-ui/react'
import { colors } from '../../constants'

export const DarkButton = (props: ButtonProps) => (
  // @ts-ignore
  <Button background='gray.800' _hover={{ bg: 'gray.500' }} color='white' {...props} />
)

const activeProps = {
      boxShadow: `0 0 9px ${colors.lightblue}`, 
      color: colors.lightblue,
      opacity: 1,
      background: 'blackAlpha.500'
}
export const GhostButton = forwardRef((props: ButtonProps, ref) => (
  <Button 
    ref={ref}
    m={2} 
    color='white' 
    variant='unstyled' 
    opacity={0.9}
    bg='blackAlpha.400'
    padding='0 2rem'
    display='flex'
    alignItems='center'
    boxShadow='0 0 6px rgba(255,255,255,0.6)'
    _hover={activeProps}
    _focus={{boxShadow: '0 0 9px rgba(255, 255, 255,0.6)'}} 
    {...props
    }/>
))
