export const castDate = (date: Date | number) => (date instanceof Date ? date : new Date(date))

export const getDateTimeString = (date: Date | number) => (
  castDate(date)
).toLocaleString('en-US', {
  minute: '2-digit',
  hour: 'numeric',
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
})

export const toDay = (date: number | Date) => {
  const d = castDate(date)
  d.setHours(0, 0, 0, 0)
  return d.getTime()
}
export const getTimeString = (date: Date | number) => (
  castDate(date)
).toLocaleDateString('en-US', {
  minute: '2-digit',
  hour: 'numeric',
})

export const getDayMonthString = (
  date: Date | number,
  dateStyle: 'long' | 'short' = 'long',
  utc: boolean = true,
) => {
  let options: Intl.DateTimeFormatOptions = {}
  if (utc) options.timeZone = 'UTC'
  if (dateStyle === 'long') {
    options = {
      ...options,
      month: 'long',
      day: 'numeric',
      weekday: 'long',
    }
  }

  return (castDate(date)).toLocaleDateString('en-US', options)
}
export const getDateString = (
  date: Date | number,
  dateStyle: 'long' | 'short' = 'long',
  utc: boolean = true,
) => {
  let options: Intl.DateTimeFormatOptions = {}
  if (utc) options.timeZone = 'UTC'
  if (dateStyle === 'long') {
    options = {
      ...options,
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
    }
  }

  return (castDate(date)).toLocaleDateString('en-US', options)
}
export const addNDaysTo = (to: number | Date, n: number) => {
  const d = castDate(to)
  d.setDate(d.getDate() + n)
  return d.getTime()
}
export const getNDaysFromNow = (n: number) => addNDaysTo(new Date(), n)
