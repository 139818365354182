import { Flex, Text, VStack } from '@chakra-ui/react'
import { FC, useMemo, useState } from 'react'
import { toggleQueueOpen } from '../../api/players'
import { useApp } from '../../hooks'
import { SessionPreview } from '../sessions/SessionPreview'
import { GhostButton } from '../shared/Buttons'
import { ExpandOnMount } from '../shared/ExpandOnMount'
import { LoadingOverlay } from '../shared/LoadingOverlay'
import { JoinButton } from './JoinButton'

const ToggleQueueOpen: FC<{id?: string}> = ({id}) => {
  const { account, selectedPlayer } = useApp()
  const { data: player } = selectedPlayer || {}
  const { queueOpen } = player || {}
  const { id: uid } = account || {}
  const show = useMemo(() => uid && id === uid, [uid, id])
  const [isLoading, setIsLoading] = useState(false)
  return (
    <ExpandOnMount in={!!show}>
      <Flex mb={2} p={1} w='100%'>
      <GhostButton 
        m={0}
        isLoading={isLoading}
        onClick={async () => {
          setIsLoading(true)
          await toggleQueueOpen()
          setIsLoading(false)
        }} 
        w='100%'>
          {queueOpen ? 'CLOSE' : 'OPEN'}
          </GhostButton>
      </Flex>
    </ExpandOnMount>
  )
}

export const PlayerQueue: FC<{ id?: string }> = ({ id }) => {
  // const { data, isLoading } = usePlayerQueue(id)
  const { selectedPlayer } = useApp()
  const { data: player, isLoading } = selectedPlayer || {}
  const {queue: data, isVerified, activity, queueOpen, tag} = player || {}
  const text = useMemo(() => {
    const name = tag || 'This player'
    if(!isVerified) return `${name} isn't verified yet`
    if(activity?.state !== 'online') return `${name} is offline`
    if(!queueOpen) return `${name}'s queue is closed`
    return `No games in queue... yet`
  }, [isVerified, activity, queueOpen, tag])
  return (
    <VStack height='400px' px={2} py={2} w='100%'>
      <VStack w='100%' p={2} overflowY='auto' borderRadius={6} flex={1} background='blackAlpha.500'>
      {data && data.length ? (
        data.map((d, index) => (
          <SessionPreview standalone canOpenPlayers id={d} key={index} />
        ))
      ) : (
        <Text py={3}>{text}</Text>
      )}
      </VStack>
      <JoinButton/>
      <ToggleQueueOpen id={id}/>
      <LoadingOverlay isLoading={!!isLoading} />
    </VStack>
  )
}
