import { Tooltip } from "@chakra-ui/react";
import { Player } from "@testme/shared";
import { motion, Variants } from 'framer-motion';
import { FC, useMemo } from "react";
import { colors } from "../../constants";

const formatter = new Intl.DateTimeFormat(undefined, {dateStyle: 'long', timeStyle: 'short'})
const toDateString = (date: any) => {
  return formatter.format(date)
}

const variants: Variants = {
  initial: {
    scale: 0.8,
    opacity: 0.8,
    fill: `${colors.lightblue}00`,
  },
  online: () => ({
    fill: `${colors.lightblue}ff`,
    scale: 0.9,
    opacity: 1,
    transition: {
      duration: 1,
      repeatType: 'reverse',
      repeat: Infinity
    }
  })
};
export const OnlineIndicator:FC<{player?: Player}> = ({player}) => {
  const isOnline = useMemo(() => player?.activity?.state === 'online', [player])
  const label = useMemo(() => {
    if(player?.activity?.state === 'online') return 'Online'
    if(player?.activity?.last_changed) return `Last online ${toDateString(player.activity.last_changed.seconds*1000)}`
    return 'Offline'
  }, [player] )
  return(
    <Tooltip placement='left' label={label}>

    <svg 
      style={{opacity: player ? 1 : 0, transition:'opacity 500ms'}} height='20px' width='20px' viewBox="-50 -50 100 100">
      <circle opacity={0.7} cx={0} cy={0} r={45} fill='#777'/>
      <circle cx={0} cy={0} r={38} fill='rgba(0,0,0,0.8)'/>
      <motion.circle
        variants={variants} 
        initial='initial'
        animate={isOnline ? 'online' : 'initial'}
        cx={0} 
        cy={0}
        r={30} />
    </svg>
    </Tooltip>
  )
}