import { Center, Flex, Image, Text, useToast, VStack } from '@chakra-ui/react'
import { useFirestoreDocumentData } from '@react-query-firebase/firestore'
import {
  Chat,
  ChatMessage, getDayMonthString,
  Player,
  toDay
} from '@testme/shared'
import { doc, DocumentReference } from 'firebase/firestore'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { sendChat } from '../../api/chat'
import { db } from '../../api/db'
import { getPlayerDoc } from '../../api/players'
import sendIcon from '../../assets/images/send.svg'
import { colors } from '../../constants'
import { useApp } from '../../hooks'
import { FieldForm } from '../forms/Form'
import { GhostButton } from '../shared/Buttons'
import { ExpandOnMount } from '../shared/ExpandOnMount'
import { ImageLoader } from '../shared/ImageLoader'
import { LoadingOverlay } from '../shared/LoadingOverlay'

const ChatMessageView: FC<{ message: ChatMessage }> = ({ message }) => {
  const { account } = useApp()
  const { id } = account || {}
  const { data: postedBy } = useFirestoreDocumentData<Player>(
    ['players', message.postedBy || '__TEST__'],
    getPlayerDoc(message.postedBy)
  )
  const isMine = useMemo(() => id === message.postedBy, [id, message])
  return (
    <ExpandOnMount in>
      <Flex
        w='100%'
        justify='flex-start'
        flexFlow={isMine ? 'row-reverse' : 'row'}
        px={2}
        py={1}
      >
        <Flex
          borderRadius='full'
          overflow='hidden'
          h='30px'
          minH='30px'
          w='30px'
          minW='30px'
        >
          <ImageLoader src={postedBy?.image?.url} alt={postedBy?.tag || ''} />
        </Flex>
        <Flex
          px={3}
          mx={2}
          py={1}
          borderRadius={16}
          justify='center'
          minW='50px'
          bg={isMine ? 'whiteAlpha.200' : colors.blue}
        >
          <Text>{message.text}</Text>
        </Flex>
      </Flex>
    </ExpandOnMount>
  )
}

type ChatDay = {
  date: number
  messages: Array<ChatMessage>
}
const ChatDayView: FC<ChatDay> = ({ date, messages }) => (
  <VStack spacing={0} w='100%'>
    <Center px={2} w='100%'>
      <Flex h='2px' flex={1} bg='whiteAlpha.500' />
      <Text opacity={0.8} px={2} as='span'>
        {getDayMonthString(date)}
      </Text>
      <Flex h='2px' flex={1} bg='whiteAlpha.500' />
    </Center>
    {messages.map((m) => (
      <ChatMessageView message={m} key={`${m.postedOn}`} />
    ))}
  </VStack>
)

const useSendChat = () => {
  const { account } = useApp()
  const uid = account?.id
  const toast = useToast()
  return useCallback(
    async (chatId?: string, message?: string) => {
      if (!uid || !chatId) {
        toast({ status: 'error', description: 'Internal error sending chat' })
        return undefined
      }
      const trimmed = (message || '').trim()
      if (!trimmed) return undefined

      return sendChat(chatId, uid, trimmed)
        .then(() => undefined)
        .catch((err) => {
          toast({ status: 'error', description: 'Internal error sending chat' })
          console.error(err)
          return undefined
        })
    },
    [uid, toast]
  )
}

export const SessionChat = () => {
  const { selectedSession } = useApp()
  const chatId = selectedSession?.data?.chatId
  const send = useSendChat()
  const { data: chat, isLoading } = useFirestoreDocumentData<Chat>(
    ['chat', chatId || '__TEST__'],
    doc(db, `chats/${chatId}`) as DocumentReference<Chat>,
    { subscribe: true },
    { enabled: !!chatId }
  )
  const [mounted, setMounted] = useState(false)
  const messages = chat?.messages
  const scrollRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (!scrollRef.current) return
    const delta =
      scrollRef.current.scrollHeight - scrollRef.current.scrollTop - 350
    // console.log(delta)
    if (delta < 50)
      setTimeout(() => {
        if (!scrollRef.current) return
        scrollRef.current.scrollTo({
          top: scrollRef.current.scrollHeight - scrollRef.current.clientHeight,
          behavior: 'smooth',
        })
      }, 500)
  }, [messages])
  useEffect(() => {
    if(mounted || !messages) return
     const t = setTimeout(() => {
      setMounted(true)
      if (!scrollRef.current) return
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight - scrollRef.current.clientHeight,
        // behavior: 'smooth',
      })
    }, 1000)
    return  () => {
      clearTimeout(t)
    }
  }, [messages, mounted])

  const groupedByDay = useMemo<Array<ChatDay>>(() => {
    const record = (messages || []).reduce((acc, curr) => {
      const messageDay = toDay(curr.postedOn)
      if (!acc[messageDay]) acc[messageDay] = []
      acc[messageDay].push(curr)
      return acc
    }, {} as Record<number, Array<ChatMessage>>)
    return Object.entries(record).map(([date, messages]) => ({
      date: parseInt(date),
      messages,
    }))
  }, [messages])
  return (
    <VStack p={0} w='100%' h='400px'>
      <VStack
        height='350px'
        ref={scrollRef}
        overflowY='auto'
        w='100%'
        flex={1}
        align='flex-start'
          bg='blackAlpha.400'
      >
        <VStack
          justify='flex-end'
          // flexFlow='column-reverse'
          opacity={mounted ? 1 : 0}
          transition='opacity 500ms'
          w='100%'
          flex={1}
          py={1}
        >
          {groupedByDay.length ? (
            groupedByDay.map((day) => (
              <ChatDayView key={`${day.date}`} {...day} />
            ))
          ) : (
            <Text p={4} fontSize='sm' opacity={0.7}>
              No messages yet
            </Text>
          )}
        </VStack>
      </VStack>
      <ExpandOnMount style={{ marginTop: 0 }} in={!isLoading}>
        <Flex w='100%'>
          <FieldForm
            onSubmit={async (d, form) => {
              // return undefined
              const res = await send(chatId, d || '')
              form.reset({ value: '' })
              return res
            }}
            field={{
              _type: 'textarea',
              optional: true,
              placeholder: 'Say something...',
            }}
            renderFooter={({ handleSubmit, submitting, values }) => {
              return (
                <GhostButton
                  size='xs'
                  bg={values?.value ? colors.blue : 'blackAlpha.400'}
                  position='absolute'
                  // opacity={0.7}
                  pointerEvents={values?.value ? 'auto' : 'none'}
                  _hovered={{ opacity: 1 }}
                  bottom={8}
                  right={8}
                  style={{ padding: 0 }}
                  minW={0}
                  w={7}
                  h={7}
                  px={0}
                  borderRadius='full'
                  onClick={handleSubmit}
                  isLoading={submitting}
                >
                  <Image src={sendIcon} w='15px' />
                </GhostButton>
              )
            }}
          />
        </Flex>
      </ExpandOnMount>
      <LoadingOverlay isLoading={isLoading || !mounted} />
    </VStack>
  )
}
