import {
  GetStripeAccountLinkArgs,
  GetStripeAccountLinkResponse,
} from '@testme/shared'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { app } from './initialize'

export const functions = getFunctions(app)

export const getStripeAccountLink = httpsCallable<
  GetStripeAccountLinkArgs,
  GetStripeAccountLinkResponse
>(functions, 'getStripeAccountLink')

export const testFunc = httpsCallable(functions, 'testFunc')
