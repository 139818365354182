import { useFirestoreQuery } from "@react-query-firebase/firestore";
import { limit, query, where } from "firebase/firestore";
import { useMemo } from "react";
import { paymentsCollection } from "../api/payments";
import { firstDocSelector } from "../api/utils";

// Users can only have a single active subscription
const getSessionPaymentQuery = (id: string) => query(
  paymentsCollection,
  where("session", "==", id),
  limit(1)
)

// Override the return type generic:
export const useSessionPayment = (id?: string) => {
  const currentGameQuery = useMemo(() => getSessionPaymentQuery(id || '__TEST__'), [id])
return useFirestoreQuery(
  ["sessionPayment", id || '__TEST__'],
  currentGameQuery,
  {
    subscribe: true,
  },
  {
    enabled: !!id,
    select: firstDocSelector
  }
);
} 