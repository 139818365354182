import { useFirestoreQuery } from "@react-query-firebase/firestore";
import { SessionStatus } from "@testme/shared";
import { limit, query, where } from "firebase/firestore";
import { useMemo } from "react";
import { sessionsCollection } from "../api/session";
import { firstDocSelector } from "../api/utils";
import { useApp } from "./useApp";

// Users can only have a single active subscription
const getPaymentProcessingQuery = (id: string) => query(
    sessionsCollection, 
    where('challenger','==', id), 
    where('status', '==',SessionStatus.PAYMENT_PROCESSING),
  where("createdOn", '>', Date.now() - 1000*60),
    limit(1)
    )

// Override the return type generic:
export const usePaymentProcessing = () => {
  const {account} = useApp()
  const uid = account?.id
  const paymentProcessingQuery = useMemo(() => getPaymentProcessingQuery(uid || '__TEST__'), [uid])
return useFirestoreQuery(
  "paymentProcessing",
  paymentProcessingQuery,
  {
    subscribe: true,
  },
  {
    enabled: !!uid,
    select: firstDocSelector
  }
);
} 