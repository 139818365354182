import { Button, Flex, Menu, MenuButton, MenuItem, MenuList, Text, useDisclosure } from "@chakra-ui/react";
import { SelectField } from "@testme/shared";
import { FC, useMemo } from "react";
import { InputProps } from "../types";

const DefaultOption: FC<{option: any}> = ({option}) => {
  const {text} = option || {}
  return(
    <Flex px={5} py={3} bg='#172030' _hover={{bg:'#283854'}} w='100%' align='center'>
      <Text color='whiteAlpha.900' fontSize='1rem' mr={2}>{text}</Text>
    </Flex>
  )
}

export const SelectComponent: FC<InputProps<SelectField>> = ({field, input, meta}) => {
  const {isOpen, onClose, onOpen} = useDisclosure()
  const {placeholder, label, options, renderOption} = field
  const {onChange, value} = input
  const selected = useMemo(() => value && options.find(o => o.id === value), [value, options])
  const displayed = useMemo(() => {
    if(!selected) return placeholder
    return renderOption ? renderOption(selected) : <DefaultOption option={selected}/>
  }, [selected, renderOption, placeholder])
  return (
    <Flex flexFlow='column' align='center' w='100%'>
      {label ? <Text>{label}</Text> : null}
      <Menu matchWidth isOpen={isOpen} onClose={onClose}>
        <MenuButton 
        background='whiteAlpha.300'
          _hover={{background: 'whiteAlpha.400'}} 
          // height={14} 
          w='100%' 
          height='auto'
          fontSize='0.8rem'
          letterSpacing='0.1rem'
          minH={10}
          // style={{padding: '0'}}
          onClick={e => {
            e.stopPropagation()
            onOpen()
          }} 
          variant='unstyled'
          as={Button} 
          >
          {displayed}
        </MenuButton>
        <MenuList p={0} overflow='hidden'>
          {options.map(o => (<MenuItem p={0} key={o.id} onClick={() => onChange(o.id)}>
          {renderOption ? renderOption(o) : <DefaultOption option={o}/>}
          </MenuItem>
            ))}
        </MenuList>
      </Menu>
    </Flex>
  )
}