import {
  createUserWithEmailAndPassword, getAuth,
  GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup,
  signOut as fbSignOut
} from 'firebase/auth'
import { doc, serverTimestamp, updateDoc } from 'firebase/firestore'
import { app } from '../../api/initialize'
import { db } from '../db'

export const auth = getAuth(app)
const provider = new GoogleAuthProvider()
export const googleSignIn = () => signInWithPopup(auth, provider)
export const signOut = async  () => {
  updateDoc(doc(db,`players/${auth.currentUser?.uid}`), {activity:{
    state: 'offline',
    last_changed: serverTimestamp(),
  }})
  fbSignOut(auth)
} 
export const emailRegister = (data: any) => createUserWithEmailAndPassword(auth, data.email, data.password)
export const emailSignIn = (data: any) => signInWithEmailAndPassword(auth, data.email, data.password)
