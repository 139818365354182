import { Button, usePrevious } from "@chakra-ui/react"
import { Player } from "@testme/shared"
import { useEffect, useMemo } from "react"
import useSound from "use-sound"
import { useMyPlayer } from "../../../../api/players"
import { useApp } from "../../../../hooks"
import { useMyCurrentGame } from "../../../../hooks/useMyCurrentGame"
//@ts-ignore
import newInQueueSound from '../../../../sounds/new-in-queue.wav'

const useChallangerNotif = (play: () => void) => {

  const {data: currentGame} = useMyCurrentGame()
  const prev = usePrevious(currentGame)
  useEffect(() => {
    if(currentGame && currentGame.id !== prev?.id) play()
  }, [play, prev, currentGame])
}

const useNewInQueueNotif = (play: () => void, player?: Player) => {

  const prevQueueLength = usePrevious(player?.queue?.length)
  const queueLength = player?.queue?.length
  useEffect(() => {
    if((queueLength || 0) > (prevQueueLength || 0)){
      play()
    }
  }, [queueLength, prevQueueLength, play])
}

export const Notifications = () => {
  const {account, selectedPlayer} = useApp()
  const [play] = useSound(newInQueueSound, {volume: 0.3})
  const uid = account?.id
  const select = selectedPlayer?.select
  const {player} = useMyPlayer()
  const show = useMemo(() => player?.queueOpen && player?.queue?.length, [player])
  useChallangerNotif(play)
  useNewInQueueNotif(play, player)

  return(
    <Button
      aria-label="queue alert"
      variant='ghost'
      onClick={() => select && uid && select(uid)}
      // onClick={() => play()}
      pos='absolute'
      right={3}
      bottom={3}
      opacity={show ? 0.7 : 0}
      transition='all 500ms'
      bg='whiteAlpha.300'
      borderRadius='full'
      color='white'
      textShadow='0 0 5px black'
      fontWeight={600}
      letterSpacing='0'
      _hover={{opacity: 0.9}}
      >
      {player?.queue?.length || ''}
      </Button>
  )
}