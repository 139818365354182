import { CheckIcon } from '@chakra-ui/icons'
import {
  Center,
  Collapse,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner, TabList,
  TabPanel,
  TabPanels, Tabs,
  Text,
  usePrevious,
  VStack
} from '@chakra-ui/react'
import { Player } from '@testme/shared'
import { FC, useMemo } from 'react'
import { colors } from '../../constants'
import { useApp } from '../../hooks'
import { ImageLoader } from '../shared/ImageLoader'
import { GlowText, SolidTab } from '../shared/Text'
import { OnlineIndicator } from './OnlineIndicator'
import { PlayerPastGames } from './PlayerPastGames'
import { PlayerQueue } from './PlayerQueue'

const PlayerHeader: FC<{ player?: Player }> = ({ player }) => {
  const prev = usePrevious(player)
  const {image, tag, fname, lname, numCompleted} = player || prev || {}
  return(
  <Flex>
    <Flex
      w='130px'
      h='130px'
      bg='whiteAlpha.500'
      opacity={player ? 1 : 0}
      borderRadius={6}
      overflow='hidden'
    >
      {image?.url ? <ImageLoader src={image.url} /> : null}
    </Flex>
    <VStack
      minH='100%'
      spacing={0}
      flex={1}
      justify='flex-start'
      align='flex-start'
      pl={4}
      pr={2}
      // py={1}
    >
      <Flex w='100%' justify='space-between' direction='row' align='center'>

      <GlowText fontSize='1.4rem' color={colors.lightblue}>
        {tag || ''}
      </GlowText>
      <OnlineIndicator player={player}/>
      </Flex>
      <GlowText transform='translateY(-5px)' fontSize='1.1rem'>
        {`${fname || ''} ${lname || ''}`.trim()}
      </GlowText>
      <Flex align='center'>
        <CheckIcon mr={2} fontSize='1rem' color={colors.lightblue} />
        <Text letterSpacing='0.12rem' fontSize='0.8rem' color='gray.200'>
          VERIFIED
        </Text>
      </Flex>
      <Flex flex={1} align='flex-end' pt={1}>
        <GlowText intensity={0.4} mr='0.5rem' color={colors.lightblue}>
          {numCompleted || 'No'}
        </GlowText>
        <Text opacity={0.8}> MATCH{numCompleted !== 1 ? 'ES' : ''} PLAYED</Text>
      </Flex>
    </VStack>
  </Flex>
)
} 

const PlayerBody = () => {
  const { selectedPlayer } = useApp()
  const { id } = selectedPlayer || {}
  return (
    <Tabs
      flex={1}
      isLazy
      display='flex'
      flexFlow='column'
      mt={2}
      variant='solid'
    >
      <TabList>
        <SolidTab borderTopLeftRadius={6}>QUEUE</SolidTab>
        <SolidTab borderTopRightRadius={6}>PAST GAMES</SolidTab>
      </TabList>
      <TabPanels
        flex={1}
        borderRightRadius={6}
        borderBottomLeftRadius={6}
        background='whiteAlpha.300'
      >
        <TabPanel p={0}>
          <PlayerQueue id={id || undefined} />
        </TabPanel>
        <TabPanel p={0}>
          <PlayerPastGames id={id || undefined} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}


const PlayerFullView = () => {
  const { selectedPlayer } = useApp()
  const { id, data: player, isLoading, error } = selectedPlayer || {}
  const showLoading = useMemo(
    () => id && !player && isLoading,
    [id, player, isLoading]
  )
  return (
    <VStack minH='500px'>
      <Flex
        flex={1}
        w='100%'
        direction='column'
        opacity={player ? 1 : 0}
        transition='opacity 500ms'
      >
        <PlayerHeader player={player} />
        <Collapse in={!!player}>
        <PlayerBody />
        </Collapse>
      </Flex>
      <Collapse in={!!error} style={{ width: '100%' }}>
        <GlowText fontSize='lg' color='red'>
          We can't find that player
        </GlowText>
        <GlowText fontSize='md' color='gray.200'>
          {error?.message || ''}
        </GlowText>
      </Collapse>
      <Center
        opacity={showLoading ? 1 : 0}
        width='100%'
        height='100%'
        position='absolute'
        pointerEvents={showLoading ? 'auto' : 'none'}
        transition='opacity 500ms'
      >
        <Spinner
          speed='0.8s'
          thickness='6px'
          color={colors.lightblue}
          size={'xl'}
        />
      </Center>
    </VStack>
  )
}

export const PlayerFullViewModal = () => {
  const { selectedPlayer } = useApp()
  const { id, deselect } = selectedPlayer || {}

  return (
    <Modal isCentered isOpen={!!id} onClose={deselect!}>
      <ModalOverlay />
      <ModalContent overflow='hidden' m={0}>
        <ModalBody p={3}>
          <PlayerFullView />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
