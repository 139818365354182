import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { animate, useSpring } from 'framer-motion'
import { useEffect, useMemo, useRef } from 'react'
import { OrthographicCamera, ShaderMaterial } from 'three'
import { useApp } from '../hooks'
const isSSR = typeof window === 'undefined'
const getCameraLeft = () => (!isSSR ? window.innerWidth / -2 : 0)
const getCameraRight = () => (!isSSR ? window.innerWidth / 2 : 0)

const getCameraTop = () => (!isSSR ? window.innerHeight / 2 : 0)
const getCameraBottom = () => (!isSSR ? window.innerHeight / -2 : 0)
const vertexShader = `
  varying vec3 Position;

  void main() {
    Position = vec3(modelViewMatrix * vec4(position, 1.0));
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`
const shader = `
#define iterations 10
#define formuparam 0.53

#define volsteps 15
#define stepsize 0.2

#define zoom   0.800
#define tile   0.850
#define speed  0.0015

#define brightness 0.0015
#define darkmatter 0.300
#define distfading 0.730
#define saturation 0.850

  uniform float resX;
  uniform float resY;
	uniform float iTime;
	varying vec3 Position;
	uniform float fadeTime;

void main()
{
	//get coords and direction
	vec2 uv=Position.xy/((resX+resY)/2.0)-.5;
	// uv.y*=resY/resX;
	vec3 dir=vec3(uv*zoom,1.);
	float time=iTime*speed+.25;

	//mouse rotation
	float a1=.5+200./resX*2.;
	float a2=.8+200./resY*2.;
	mat2 rot1=mat2(cos(a1),sin(a1),-sin(a1),cos(a1));
	mat2 rot2=mat2(cos(a2),sin(a2),-sin(a2),cos(a2));
	dir.xz*=rot1;
	dir.xy*=rot2;
	vec3 from=vec3(1.,.5,0.5);
	from+=vec3(time*2.,time,-2.);
	from.xz*=rot1;
	from.xy*=rot2;
	
	//volumetric rendering
	float s=0.1,fade=1.;
	vec3 v=vec3(0.);
	for (int r=0; r<volsteps; r++) {
		vec3 p=from+s*dir*.5;
		p = abs(vec3(tile)-mod(p,vec3(tile*2.))); // tiling fold
		float pa,a=pa=0.;
		for (int i=0; i<iterations; i++) { 
			p=abs(p)/dot(p,p)-(formuparam*(fadeTime*0.2 + 0.8)); // the magic formula
			a+=abs(length(p)-pa); // absolute sum of average change
			pa=length(p);
		}
		float dm=max(0.,darkmatter-a*a*.001); //dark matter
		a*=a*a; // add contrast
		if (r>6) fade*=1.-dm; // dark matter, don't render near
		//v+=vec3(dm,dm*.5,0.);
		v+=fade;
		v+=vec3(s,s*s,s*s*s*s)*a*brightness*fade*fadeTime; // coloring based on distance
		fade*=distfading; // distance fading
		s+=stepsize;
	}
	v=mix(vec3(length(v)),v,saturation*fadeTime); //color adjust
	gl_FragColor = vec4(v*.005,1.);	
	
}
`

const Stars = ({entered}: {entered?: boolean}) => {
  const size = useThree((s) => s.size)
  const camera = useThree((s) => s.camera) as OrthographicCamera
  useEffect(() => {
    camera.left = getCameraLeft()
    camera.right = getCameraRight()
    camera.top = getCameraTop()
    camera.bottom = getCameraBottom()
    camera.updateProjectionMatrix()
  }, [size, camera])
  const material = useRef<ShaderMaterial>(null)
	const uniforms = useMemo(() => ({resX: {value:size.width}, resY: {value:size.height}, iTime: {value: 0}, fadeTime: {value: 0}}), [])

	const fade = useSpring(0, {stiffness: 12})
	useEffect(() => {
		return fade.onChange(v => {
			if(!material.current) return
			material.current.uniforms.fadeTime.value = v
		})
	}, [])

	useEffect(() => {
		animate(fade, entered ? 1 : 0, {type: 'spring', stiffness: 160, restSpeed: 0.01})
	}, [entered, fade])

	useEffect(() => {
		if(!material.current) return
		material.current.uniforms.resX.value = size.width
		material.current.uniforms.resY.value = size.height
	}, [size])
  useFrame(({clock}) => {
    if(!material.current) return
    material.current.uniforms.iTime.value = clock.getElapsedTime()
  })
  return (
    <mesh>
      <planeBufferGeometry args={[size.width, size.height]} />
      <shaderMaterial 
				ref={material} 
				uniforms={uniforms} 
				vertexShader={vertexShader}
				fragmentShader={shader} transparent opacity={0.5} />
    </mesh>
  )
}
export const Background = () => {
	const {entered} = useApp()
  return (
      <Canvas style={{position: 'fixed', pointerEvents: 'none', zIndex: 0}} orthographic>

          <Stars entered={entered} />
      </Canvas>
  )
}
