import { cssVar, extendTheme } from '@chakra-ui/react'
import { colors } from './constants'

const $arrowBg = cssVar('popper-arrow-bg')
const $arrowShadow = cssVar('popper-arrow-shadow-color')
export const theme = extendTheme({
  styles: {
    global: {
      [$arrowBg.variable]: colors.gray,
      [$arrowShadow.variable]: colors.darkgray,
    },
  },
  components: {
    Tooltip: {
      baseStyle: {
        bg: colors.lightblue,
        color: 'black',
      },
    },
    IconButton: {
      baseStyle: { _focus: { boxShadow: 'none' } },
    },
    Button: {
      baseStyle: {
        color: 'white',
        fontFamily: 'Encode Sans',
        fontWeight: 700,
        letterSpacing: '0.16rem',
        _focus: { boxShadow: 'none' },
      },
      variants: {
        ghost: {
          _hover: { background: 'blackAlpha.500' },
        },
        solid: {
          background: colors.blue,
          _hover: {
            background: colors.lightblue,
          },
        },
      },
    },
    Tabs: {
      baseStyle: {
        tab: { _focus: { boxShadow: 'none' } },
      },
    },
    Text: { baseStyle: { fontFamily: 'Encode Sans', color: 'white' } },
    Popover: {
      baseStyle: {
        root: { _focus: { boxShadow: 'none' } },
        content: {
          background: 'whiteAlpha.400',
          border: 'none',
          boxShadow: '0 0 4px rgba(0,0,0,0.5)',
        },
      },
    },
    Modal: {
      baseStyle: {
        dialog: {
          boxShadow: '0 0 12px rgba(255,255,255,0.5)',
          background: '#111',
          color: 'white',
        },
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          _focus: { boxShadow: 'none' },
          _checked: {
            bg: colors.darkgray,
            color: colors.lightblue,
            border: 'none',
          },
          _hover: { bg: colors.gray, border: 'none' },
        },
      },
      variants: {
        noBox: {
          control: {
            bg: 'transparent',
            _hover: { bg: 'none' },
          },
        },
      },
    },
    Switch: {
      baseStyle: {
        track: {
          _checked: { bg: colors.lightblue },
          _focus: { boxShadow: 'none' },
        },
      },
    },
    Menu: {
      baseStyle: {
        list: {
          bg: 'rgba(0,0,0,0.8)',
          border: 'none',
          boxShadow: '0 0 6px rgba(255,255,255,0.5)'
        },
        item: {
          _hover: {
          bg: 'rgba(255,255,255,0.3)'
          },
          _focus: {
            bg: 'rgba(255,255,255,0.2)'
          }
        }
      },
    },
  },
})