import { Collapse, TabPanel, TabPanels, Tabs, VStack } from '@chakra-ui/react'
import { FC } from 'react'
import { ScrubPanel } from './ScrubPanel'
import { TopPlayerStatus } from './topPlayer/TopPlayerStatus'

export const AccountStatus: FC<{ mode: 'scrub' | 'top-player' }> = ({
  mode,
}) => {
  return (
    <VStack w='100%' align='flex-start' flex={1} px={2} py={1}>
      <Tabs display='flex' flexFlow='column' flex={1} w='100%' index={mode === 'scrub' ? 0 : 1}>
        <TabPanels display='flex' flexFlow='column' flex={1}>
          <TabPanel p={0}>
            <Collapse in={mode === 'scrub'}>
              <ScrubPanel />
            </Collapse>
          </TabPanel>
          <TabPanel display='flex' flexFlow='column' flex={1} p={0}>
            <Collapse style={{flex: 1, display: 'flex', flexFlow: 'column'}} in={mode === 'top-player'}>
              <TopPlayerStatus />
            </Collapse>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  )
}
