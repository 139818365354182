import {
  useFirestoreDocument, useFirestoreDocumentData, useFirestoreQuery
} from '@react-query-firebase/firestore'
import { EditPlayerArgs, Player } from '@testme/shared'
import { collection, CollectionReference, doc, query, where } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { useMemo } from 'react'
import { useApp } from '../hooks'
import { useActiveStatus } from '../hooks/useActiveStatus'
import { db } from './db'
import { functions } from './functions'
export const playersCollection = collection(db, 'players') as CollectionReference<Player>
export const getPlayerDoc = (uid?: string) =>
  doc(db, `players/${uid || 'null'}`)

export const useMyPlayer = () => {
  const { account, mode } = useApp()
  const authUser = account?.auth?.data
  const { data: player, isLoading } = useFirestoreDocumentData<Player>(
    ['players', account?.id || '__TEST__'],
    doc(db, `players/${authUser?.uid || '__TEST__'}`),
    { subscribe: true },
    { enabled: !!authUser?.uid, refetchOnMount: true }
  )
  useActiveStatus(player?.isVerified && mode === 'top-player' ? authUser?.uid : undefined)
  return { player, isLoading }
}

// export const usePlayers = () => {
//   const { data, error, ...stuff } = useFirestoreInfiniteQuery<Player>(
//     'players', 
//     query(playersCollection, where('isVerified', '==', true)
//     ), (snapshot) => {
//     const lastDocument = snapshot.docs[snapshot.docs.length - 1];
//     // Get the next 20 documents starting after the last document fetched.
//     if(lastDocument){
//     return query(playersCollection, where('isVerified', '==', true), startAfter(lastDocument));
//     }
//     else return query(playersCollection, where('isVerified', '==', true))
    
//   })
//   const players = useMemo(
//     () => {
//       const res = [] as Array<Player>
//       for(let i = 0; i < (data?.pages?.length || 0); i += 1){
//         res.push(...data!.pages[i].docs.map(d => ({...d.data(), id:d.id})))
//       }
//       return res
//     },
//     [data]
//   )
//   return { players, ...stuff }
// }
export const usePlayers = () => useFirestoreQuery('playerIds', query<Player>(
  playersCollection, 
  where('isVerified', '==', true)), 
  {subscribe: true}, 
  {
    select(snapshot) {
      return snapshot.docs.map((docSnapshot) => docSnapshot.id);
    },
})

export const toggleQueueOpen = httpsCallable<void, void>(functions, 'toggleQueueOpen')
export const editPlayer = httpsCallable<EditPlayerArgs, void>(functions, 'editPlayer')