import { Center, Flex } from "@chakra-ui/react"
import { FC, useRef } from "react"
import { useApp } from "../../hooks"
import { TopPlayers } from "../players/TopPlayers"
import { GlowText } from "../shared/Text"
import { DownArrow } from "./DownArrow"
import { HowItWorks } from "./HowItWorks"

export const Intro:FC<{splashHeight: number}> = ({splashHeight}) => {
  const { entered,  screen } = useApp()
  const contentRef = useRef<HTMLDivElement>(null)
  return (
        <Flex
          flexFlow='column'
          mb={6}
          width='100%'
          px={2}
        >

        <Flex 
        minHeight={splashHeight}
        pb={[2,2,6]}
        ref={contentRef}
        opacity={entered ? 1 : 0} transition='opacity 500ms ease 1000ms'
        width='100%' direction='column' align='center'>
        <GlowText
          textAlign='center'
          px={6}
          fontFamily='Russo One'
          fontSize='1.8rem'
          color='whiteAlpha.900'
          mt={[8, 8, 24]}
          mb={4}
        >
          PAY TO PRACTICE AGAINST TOP PLAYERS
        </GlowText>
        <HowItWorks />
        <Center onClick={
          () => {
            document.body.scrollTo({top: screen?.height || 0, behavior: 'smooth'})
        }} 
        flexFlow='column' flex={1} justifyContent='flex-end'>
        <GlowText mb={4} fontFamily='Russo One' fontSize='2rem'>PLAYERS</GlowText>
        <DownArrow/>
        </Center>
        </Flex>
        <Flex mt={8} flexFlow='column'>

        <TopPlayers height={screen ? screen.height - 80 : 0}/>
        </Flex>
        </Flex>
  )
}