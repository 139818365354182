import { Player, Session, User } from '@testme/shared'
import {
  collection,
  CollectionReference,
  getFirestore,
} from 'firebase/firestore'
import { app } from './api/initialize'

const db = getFirestore(app)

const getCollection = <T>(name: string) =>
  collection(db, name) as CollectionReference<T>
export const USERS = getCollection<User>('users')
export const SESSIONS = getCollection<Session>('sessions')
export const PLAYERS = getCollection<Player>('players')

export const colors = {
  gray: '#474747',
  darkgray: '#333333',
  blue: '#688df7',
  lightblue: '#a3e5fb',
}
export const blueGradient = 'linear-gradient(311deg, rgba(104,141,247,1) 0%, rgba(163,229,251,1) 100%)'
export const reverseBlueGradient = 'linear-gradient(311deg, rgba(163,229,251,1) 0%, rgba(104,141,247,1) 100%)'

export const STRIPE_PK = 'pk_live_51KuZhPBFNuUhzJHFwutKAAUDtLjEKerwK4ajONItQJjVFwqlIkRCr0arv1UPfnZDD3Ben7qtNa8kMncHyqPZfTdn00BbrZjqyx'
