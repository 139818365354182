import { Checkbox, Flex } from '@chakra-ui/react'
import { FC } from 'react'
import { GlowText } from '../../shared/Text'

export const Step: FC<{ 
  isComplete: boolean, 
  onClick?: () => void 
}> = ({ children, isComplete, onClick }) => {
  return (
    <Flex onClick={onClick}>

    <Checkbox isChecked={isComplete}>
      <GlowText>{children}</GlowText>
    </Checkbox>
    </Flex>
  )
}
