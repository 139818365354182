import { useFirestoreDocumentData } from "@react-query-firebase/firestore"
import { doc, DocumentReference } from "firebase/firestore"
import { useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { db } from "../../api/db"

export const useSelected = <T>(basePath: string) => {
  const {pathname} = useLocation()
  const navigate = useNavigate()
  const selectedId = useMemo(() => {
    if(pathname.indexOf(`/${basePath}/`) === 0){
      return pathname.substring(basePath.length + 2)
    }
    return null
  }, [pathname, basePath])
  const ref = useMemo(() => doc(db, `${basePath}s/${selectedId}`) as DocumentReference<T>, [selectedId, basePath])
  const {data: fetched, ...stuff} = useFirestoreDocumentData<T>([basePath, selectedId], ref, {subscribe: true}, {enabled: !!selectedId})
  const [data, set] = useState<T | undefined>()
  useEffect(() => {
    
    set(undefined)
  }, [selectedId, pathname])
  useEffect(() => {
    set(fetched)
  }, [fetched])
  return {
      id: selectedId, 
      data,
      // select: (id: string) => console.log(id), 
      select: (id: string) => navigate(`/${basePath}/${id}`), 
      deselect: () => navigate('../..'), 
      ...stuff
    }
}