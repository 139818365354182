import { ButtonProps, Collapse, Stack, Text, useToast } from '@chakra-ui/react'
import { FieldMap, isField, isRecordField } from '@testme/shared'
import { AnyObject, FormApi, FORM_ERROR } from 'final-form'
import arrayMutators from 'final-form-arrays'
import { FC, useMemo } from 'react'
import { Form as FinalForm, FormRenderProps } from 'react-final-form'
import { colors } from '../../constants'
import { GhostButton } from '../shared/Buttons'
import { FormElement } from './input'
import { FieldFormProps, FieldMapFormProps, FormProps } from './input/types'
import { validateFieldMap } from './input/utils'

export const SubmitButton: FC<
  Pick<FormRenderProps, 'handleSubmit' | 'submitting'> & ButtonProps
> = ({ handleSubmit, submitting, ...props }) => (
  <GhostButton bg={colors.blue} textShadow='0 0 6px black' isLoading={submitting} onClick={handleSubmit} ml='auto' {...props}/>
)



export const FieldMapForm: FC<FieldMapFormProps> = ({
  onSubmit,
  field,
  value,
  buttonText = 'SUBMIT',
  renderFooter,
}) => {
  const toast = useToast()
  const handleSubmit = async (data: AnyObject, form: FormApi) => {
    try {
      await onSubmit(data, form)
      return undefined
    } catch (err: any) {
      console.log(err)
      toast({status: 'error', description: err.message || 'An error occurred'})
      return { [FORM_ERROR]: (err as any).message || 'An error occurred' }
    }
  }
  return (
    <Stack w='100%' spacing={3} py={2} px={2}>
      <FinalForm
        mutators={{...arrayMutators}}
        initialValues={value}
        validate={(v) => validateFieldMap(field, v)}
        onSubmit={handleSubmit}
        subscription={{values: true, submitting: true}}
        render={({ handleSubmit, submitting, values, errors }) => (
          <>
            <form onSubmit={handleSubmit}>
              <FormElement field={field} />
            </form>
            <Collapse in={!!errors?.[FORM_ERROR]} >
            <Text color='red'>{errors?.[FORM_ERROR] || ''}</Text>
            </Collapse>
            {renderFooter ? (
              renderFooter({ handleSubmit, submitting, values })
            ) : (
              <SubmitButton
                handleSubmit={handleSubmit}
                submitting={submitting}
              >{buttonText}</SubmitButton>
            )}
          </>
        )}
      />
    </Stack>
  )
}

export const FieldForm: FC<FieldFormProps> = ({
  onSubmit,
  field,
  value,
  ...props
}) => {
  const fieldMap = useMemo<FieldMap>(() => ({children: {value: field}}), [field])
  return(
  <FieldMapForm
    field={fieldMap}
    value={{value}}
    onSubmit={(data, form) => onSubmit(data?.value, form)}
    {...props}
  />
  )
}

export const Form: FC<FormProps> = ({field, ...props}) => {
  if(isField(field) || isRecordField(field)) return <FieldForm field={field} {...props}/>
  return <FieldMapForm field={field} {...props}/>
}