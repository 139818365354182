
import { Flex, useDisclosure } from '@chakra-ui/react'
import { useFirestoreDocumentData } from '@react-query-firebase/firestore'
import { Player, Session } from '@testme/shared'
import { createContext, ReactNode, useCallback, useContext, useMemo, useRef, useState } from 'react'
import { getPlayerDoc } from '../api/players'
import { AccountOverlay } from '../components/account/SignIn'
import { useAccount } from './firebase'
import { useSelected } from './firebase/useSelected'
import { useSize } from './useSize'

const useSelectors = () => {
  const selectedPlayer = useSelected<Player>('player')
  const selectedSession = useSelected<Session>('session')
  const {data: selectedSessionData} = selectedSession
  const sessionPlayerId = useMemo(() => selectedSessionData?.player, [selectedSessionData])
  const {data: sessionPlayer} = useFirestoreDocumentData<Player>(
    ['player', sessionPlayerId || ''], 
    getPlayerDoc(sessionPlayerId),
    {subscribe: true},
    {enabled: !!sessionPlayerId}
    )
  const deselectSession = useCallback(() => {
    if(selectedSessionData) selectedPlayer.select(selectedSessionData.player)
  }, [selectedSessionData, selectedPlayer])
return {
  selectedPlayer: {
    ...selectedPlayer, 
    data: selectedPlayer.data || sessionPlayer,
    id: selectedPlayer.id || sessionPlayerId,
  }, 
  selectedSession: {
    ...selectedSession,
    deselect: deselectSession
  }
}
}
const useAppState = () => {
  const editPlayer = useDisclosure()
  const signIn = useDisclosure()
  const joinQueue = useDisclosure()
  const help = useDisclosure()
  const verification = useDisclosure()
  const payouts = useDisclosure()
  const sessionFormats = useDisclosure()
  const account = useAccount()
  const [entered, setEntered] = useState(false)
  const [mode, setMode] = useState<'scrub' | 'top-player'>('scrub')
  const toggleMode = () => setMode(m => m === 'top-player' ? 'scrub' : 'top-player')
  const selectors = useSelectors()

  return {
    account,
    modals: { editPlayer, signIn, joinQueue, help, verification, payouts, sessionFormats },
    entered,
    mode,
    setMode,
    toggleMode,
    enter: () => setEntered(true),
    ...selectors
  }
}

export const AppContext = createContext<
  Partial<ReturnType<typeof useAppState> & {screen: ReturnType<typeof useSize>}>
>({})
export const useApp = () => useContext(AppContext)
export const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const ref = useRef<HTMLDivElement>(null)
  const screen = useSize(ref)
  const app = useAppState()
  return (
    <AppContext.Provider value={{screen, ...app}}>
      <Flex ref={ref} width='100%' height='100%' overflowY='auto'>
        {children}
          <AccountOverlay/>
      </Flex>
    </AppContext.Provider>
  )
}