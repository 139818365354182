import { Field, FieldMap, isRecordField, isField } from "@testme/shared"
import { AnyObject, ValidationErrors } from "final-form"

export const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

export const validateField = (field: Field, data: any) => {
  if(field._type === 'boolean') return undefined
  if(!field.optional && data === undefined) return 'Required'
  if(field.validate) return field.validate(data)
  switch(field._type){
    case 'text':
      switch(field.type){
        case 'email':
          if (!(data as string).match(emailRegex)) return 'Invalid email'
          return undefined
        default: 
          return undefined
      }
    default: 
      return undefined
  }
}

export const validateFieldMap = (field: FieldMap, data: AnyObject) => {
  if(field.validate) return field.validate(data)
  const errors = Object.entries(field.children)
    .reduce((acc, [fieldKey, child]) => {
      if(isRecordField(child)) return acc
      if(isField(child)) {
        const err = validateField(child, data?.[fieldKey])
        if(err) {
          if(!acc) acc = {}
          acc[fieldKey] = err
        } 
      } else {
        const err = validateFieldMap(child, data?.[fieldKey])
        if(err) {
          if(!acc) acc = {}
          acc[fieldKey] = err
        }
      }
      return acc
  }, {} as ValidationErrors)
  return errors && Object.keys(errors).length ? errors : undefined
}

export const defaultValidate = (field: Field | FieldMap, value: any) => {
  if(isField(field)) return validateField(field, value)
  else return validateFieldMap(field, value)
}