import {
  Box,
  Button,
  Collapse,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from '@chakra-ui/react'
import { FieldMap, TextField } from '@testme/shared'
import React, { useRef, useState } from 'react'
import googleLogo from '../../assets/images/googleSignIn.png'
import { useApp } from '../../hooks'
import { useSize } from '../../hooks/useSize'
import {
  emailRegister,
  emailSignIn,
  googleSignIn,
  signOut,
} from '../../utils/auth'
import { Form } from '../forms/Form'
import { emailRegex } from '../forms/input/utils'
import { DarkButton } from '../shared/Buttons'
import { GlowTab } from '../shared/Text'

const email: TextField = {
  _type: 'text',
  placeholder: 'Email',
  validate: (v) => (v?.match(emailRegex) ? undefined : 'Invalid email'),
}
const loginFields: FieldMap = {
  children: {
    email,
    password: {
      _type: 'text',
      type: 'password',
      placeholder: 'Password',
    },
  },
}

const registerFields: FieldMap = {
  validate: (v) => {
    if (!v?.email) return { email: 'Required' }
    if (!(v.email as string).match(emailRegex))
      return { email: 'Invalid email' }
    if (!v?.password) return { password: 'Required' }
    if ((v.password as string).length < 8)
      return { password: 'Password should be at least 8 characters' }
    if (!v?.confirmPassword) return { confirmPassword: 'Required' }
    if (v.confirmPassword !== v.password)
      return { confirmPassword: "Passwords don't match" }
  },
  children: {
    email,
    password: {
      _type: 'text',
      type: 'password',
      placeholder: 'Password',
    },
    confirmPassword: {
      _type: 'text',
      type: 'password',
      placeholder: 'Confirm Password',
    },
  },
}

const SignIn = () => {
  const { account, modals } = useApp()
  const onClose = modals?.signIn?.onClose
  const { auth } = account || {}
  const contentRef = useRef<HTMLDivElement>(null)
  const { data: authUser } = auth || {}
  const [isOnRegister, setIsOnRegister] = useState(false)
  const size = useSize(contentRef)
  return (
    <Collapse style={{ width: '100%' }} in={!authUser}>
      <VStack borderRadius={4} my={3} px={4} align='flex-start' w='100%'>
        <Tabs
          size='sm'
          width='100%'
          variant='unstyled'
          index={isOnRegister ? 1 : 0}
          onChange={(i) => setIsOnRegister(!!i)}
        >
          <TabList>
            <GlowTab fontSize='md'>LOG IN</GlowTab>
            <GlowTab fontSize='md'>REGISTER</GlowTab>
          </TabList>
          <Box
            overflow='hidden'
            height={size.height}
            transition='height 500ms ease'
          >
            <TabPanels ref={contentRef}>
              <TabPanel opacity={!isOnRegister ? 1 : 0} p={0}>
                <Form
                  buttonText='SIGN IN'
                  onSubmit={(d) =>
                    emailSignIn(d)
                      .then((res) => {
                        onClose && onClose()
                        return res
                      })
                      .catch((err) => {
                        console.log(err)
                        return { password: err.message }
                      })
                  }
                  field={loginFields}
                />
              </TabPanel>
              <TabPanel opacity={isOnRegister ? 1 : 0} p={0}>
                <Form
                  buttonText='REGISTER'
                  onSubmit={(d) =>
                    emailRegister(d)
                      .then(() => emailSignIn(d))
                      .then((res) => {
                        onClose && onClose()
                        return res
                      })
                      .catch((err) => {
                        console.log(err)
                        return { password: err.message }
                      })
                  }
                  field={registerFields}
                />
              </TabPanel>
            </TabPanels>
          </Box>
        </Tabs>

        <Button
          onClick={() =>
            googleSignIn().then((res) => {
              onClose && onClose()
              return res
            })
          }
          background='#EEEEEE'
          w='100%'
          ml='auto'
          padding={0}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          variant='unstyled'
          color='blackAlpha.700'
        >
          <Image src={googleLogo}></Image>
        </Button>
      </VStack>
    </Collapse>
  )
}
export const AccountOverlay = () => {
  const { account } = useApp()
  const { auth } = account || {}
  const { data: authUser } = auth || {}
  const { modals } = useApp()
  const {
    signIn: { isOpen, onClose },
  } = modals!

  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody p={2}>
            <Collapse in={!!authUser}>
              <DarkButton
                size='sm'
                background='blackAlpha.500'
                onClick={() => signOut().then(() => onClose())}
              >
                LOGOUT
              </DarkButton>
            </Collapse>
            <SignIn />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
