import {
  Center,
  Stack,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack
} from '@chakra-ui/react'
import { useState } from 'react'
import { useApp } from '../../hooks'
import { GhostButton } from '../shared/Buttons'
import { GlowInfo, GlowTab } from '../shared/Text'

export const HowItWorks = () => {
  const { modals } = useApp()
  const openSignUp = modals?.signIn?.onOpen
  const [idx, setIdx] = useState(0)
  return (
    <Stack
      mt={[0, 0, 12]}
      pb={4}
      transition={'height 500ms ease 2000ms'}
      width='100%'
      align='center'
      direction='column'
    >
      <Tabs
        index={idx}
        onChange={setIdx}
        width='100%'
        mt={4}
        variant='unstyled'
      >
        <TabList justifyContent='space-evenly'>
          <GlowTab>TOP PLAYERS</GlowTab>
          <GlowTab>THE REST OF US</GlowTab>
        </TabList>
        <TabPanels h='220px'>
          <TabPanel p={0} py={3} alignItems='center'>
            <VStack width='100%' spacing={4} direction='column'>
              <GlowInfo>
                1. Prove your identity by messaging me on twitter or twitch
              </GlowInfo>
              <GlowInfo>
                2. Connect payouts through Stripe and set your rates
              </GlowInfo>
              <GlowInfo>3. Wait for players to queue against you</GlowInfo>
              <GlowInfo fontSize={['0.9rem', '1.1rem']}>Payments will be transferred to your bank daily</GlowInfo>
            </VStack>
          </TabPanel>
          <TabPanel py={3} p={0}>
            <VStack width='100%' spacing={4} direction='column'>
              <GlowInfo>1. Pick a player to play against</GlowInfo>
              <GlowInfo>2. Pay to join their queue</GlowInfo>
              <GlowInfo>3. Practice</GlowInfo>
            </VStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Center pt={4}>
        <GhostButton size='sm' onClick={modals?.help.onOpen}>
          FAQ
        </GhostButton>
        <GhostButton size='sm' onClick={openSignUp} w='auto'>
          SIGN IN
        </GhostButton>
      </Center>
    </Stack>
  )
}
