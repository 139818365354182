import { Flex, Modal, ModalBody, ModalContent, ModalOverlay, ModalProps } from "@chakra-ui/react"
import { AnyObject, FormApi } from "final-form"
import { Form, SubmitButton } from "../forms/Form"
import { FormProps } from "../forms/input/types"
import { GhostButton } from "./Buttons"

export const FormModal = ({isOpen, onClose, field, onSubmit, value, closeOnSubmit = true}: Omit<ModalProps, 'children'> & FormProps & {closeOnSubmit?: boolean}) => {

  const handleSubmit = async (data: AnyObject, form: FormApi) => {
    const res = await onSubmit(data, form)
    if(!res && closeOnSubmit) onClose()
    if(!res) form.reset(data)
    return res
  }
  return(
    <Modal 
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay/>
      <ModalContent>
        <ModalBody>
          <Form
            field={field}
            onSubmit={handleSubmit}
            value={value}
            renderFooter={(props) => (
              <Flex borderTop='1px solid #ffffff33' pt={2}>
                <GhostButton size='xs' onClick={onClose}>CLOSE</GhostButton>
                <SubmitButton isLoading={props.submitting} size='xs' ml='auto' {...props}>SAVE</SubmitButton>
              </Flex>
            )}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}