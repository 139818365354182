import { CurrencyAmountField } from "@testme/shared";
import { FC } from "react";
import CurrencyInput from 'react-currency-input-field';
import { InputProps } from "../types";

export const CurrencyAmountComponent: FC<InputProps<CurrencyAmountField>> = ({
  input: {onChange, ...input}, 
  field
}) => {
  
  return <CurrencyInput
  style={{
    background: 'none', 
    color: 'white',
    // height: 'auto',
    resize: 'none',
    width: '100%',
    padding: '0.5rem 1rem',
    boxSizing: 'border-box',
    borderRadius: '4px',
    border: 'none',
    outline: 'none',
  }}
  placeholder={field.placeholder}
  prefix='$'
  onValueChange={v => onChange(v)}
  {...input}
  />
  
}