import {
  Center,
  Collapse,
  Flex,
  Image,
  Square,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import { useMyPlayer } from '../../api/players'
import { colors } from '../../constants'
import { useApp } from '../../hooks'
import { signOut } from '../../utils/auth'
import { EditPlayerModal } from '../players/EditPlayerModal'
import { GhostButton } from '../shared/Buttons'
import { ExpandOnMount } from '../shared/ExpandOnMount'
import { AccountStatus } from './status/AccountStatus'
import withdrawIcon from '../../assets/images/withdraw.svg'

const QuickPanel = () => {
  const { modals } = useApp()
  const { player } = useMyPlayer()
  const openEditPlayer = modals?.editPlayer?.onOpen
  return (
    <VStack
      p={2}
      direction={['row', 'row', 'column']}
      spacing={2}
      minH='120px'
      borderRadius={6}
      overflow='hidden'
      position='relative'
      w={['100%', '100%', '180px']}
      align='center'
      justify='flex-start'
    >
      {player?.image?.url ? (
        <Square
          minH='180px'
          minW='180px'
          maxH='240px'
          overflow='hidden'
          borderRadius={6}
        >
          <Image
            // position='absolute'
            src={player.image.url}
            fit='cover'
            height='100%'
            width='100%'
          />
        </Square>
      ) : null}
      <GhostButton
        w='100%'
        margin={0}
        style={{ padding: 0 }}
        size='sm'
        onClick={openEditPlayer}
      >
        PROFILE
      </GhostButton>
      <ExpandOnMount in={player?.topPlayerOptIn}>
        <Flex p={1} px={0.5}>
          <GhostButton
            w='100%'
            m={0}
            style={{ marginBottom: '0.5rem', padding: 0 }}
            size='sm'
            onClick={modals?.sessionFormats.onOpen}
          >
            <Image src={withdrawIcon} w={6} mr={2} />
            RATES
          </GhostButton>
        </Flex>
      </ExpandOnMount>
      <GhostButton
        w='100%'
        p={0}
        maxW='100%'
        style={{ margin: 0, padding: 0 }}
        size='sm'
        boxShadow='0 0 4px red'
        bg='red.600'
        onClick={signOut}
      >
        LOG OUT
      </GhostButton>
    </VStack>
  )
}

const AccountPanel = () => {
  const { mode, setMode, toggleMode } = useApp()
  const [playerLoaded, setPlayerLoaded] = useState(false)
  const { player } = useMyPlayer()
  const isTopPlayer = useMemo(() => !!player?.topPlayerOptIn, [player])
  useEffect(() => {
    if (playerLoaded || !setMode) return
    if (player) {
      if (player?.topPlayerOptIn) setMode('top-player')
      setPlayerLoaded(true)
    }
  }, [player, playerLoaded, setMode])
  return (
    <VStack h='100%' w='100%' spacing={3} flex={1}>
      <>
        {isTopPlayer ? (
          <GhostButton w='100%' onClick={toggleMode} m={0} size='sm'>
            {mode === 'top-player'
              ? 'SWITCH TO SCRUB MODE'
              : 'SWITCH TO TOP PLAYER MODE'}
          </GhostButton>
        ) : null}
        <Stack
          divider={<StackDivider opacity={0.3} />}
          w='100%'
          direction={['column', 'column', 'row']}
          p={2}
          borderRadius={6}
          bg='blackAlpha.800'
          boxShadow={`0 0 12px ${colors.gray}`}
        >
          <QuickPanel />
          <AccountStatus
            mode={mode === 'top-player' ? 'top-player' : 'scrub'}
          />
        </Stack>
      </>
    </VStack>
  )
}

export const Dashboard = () => {
  const { account } = useApp()
  const authUser = account?.auth?.data
  return (
    <Collapse
      style={{
        width: '100%',
        flex: 1,
        display: 'flex',
        flexFlow: 'column',
      }}
      in={!!authUser}
    >
      <Center flex={1} p={2} w='100%' flexFlow='column'>
        <AccountPanel />
      </Center>
      {authUser ? <EditPlayerModal /> : null}
    </Collapse>
  )
}
