import { Flex, Spinner } from '@chakra-ui/react'
import { useMemo } from 'react'
import { colors } from '../../constants'
import { useApp } from '../../hooks'
import { usePaymentProcessing } from '../../hooks/usePaymentProcessing'
import { JoinQueueModal } from '../sessions/JoinQueueModal'
import { GhostButton } from '../shared/Buttons'
import { ExpandOnMount } from '../shared/ExpandOnMount'

export const JoinButton = () => {
  const { modals, selectedPlayer, account } = useApp()
  const { id: uid } = account || {}
  const {queueOpen, activity} = selectedPlayer?.data || {}
  const playerId = selectedPlayer?.id
  const open = modals?.joinQueue?.onOpen
  const {data: processing} = usePaymentProcessing()
  const data = useMemo(() => processing?.data(), [processing])
  const shouldShow = useMemo(() => {
    if(!queueOpen) return false
    if(activity?.state !== 'online') return false
    if(!uid) return false
    if(uid === playerId) return false
    return true
  }, [queueOpen, activity, uid, playerId])

  return (
    <ExpandOnMount in={shouldShow}>
      <Flex mb={2} p={1} w='100%'>
        <GhostButton onClick={open} m={0} w='100%'>
          {data ? 
          <>
            <Spinner color={colors.lightblue} mr={2}/>
            PAYMENT PROCESSING...
          </>
          : 'JOIN'}
        </GhostButton>
      </Flex>
      <JoinQueueModal />
    </ExpandOnMount>
  )
}
