import { ChakraProvider } from '@chakra-ui/react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'
import { Background } from './components/Background'
import { Notifications } from './components/dashboard/status/topPlayer/TopPlayerOverlay'
import { Help } from './components/Help'
import { AppContextProvider } from './hooks'
import { useTimeout } from './hooks/useTimeout'
import { Home } from './pages/Home'
import { theme } from './theme'

const queryClient = new QueryClient()
function App() {
  const [mounting] = useTimeout(true, 2000)
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <AppContextProvider>
            <Background />
            {!mounting ? (
              <>
                <Routes>
                  <Route path='/' element={<Home />}>
                    <Route path='player'>
                      <Route path=':playerId' element={<Outlet />} />
                    </Route>
                    <Route path='session'>
                      <Route path=':sessionId' element={<Outlet />} />
                    </Route>
                  </Route>
                </Routes>
                <Help />
                <Notifications />
              </>
            ) : null}
          </AppContextProvider>
        </BrowserRouter>
      </ChakraProvider>
    </QueryClientProvider>
  )
}

export default App
