import { Center, Spinner } from "@chakra-ui/react";
import { FC } from "react";
import { colors } from "../../constants";


export const LoadingOverlay: FC<{isLoading: boolean}> = ({isLoading}) => (

    <Center style={{margin: 0}} position='absolute' opacity={isLoading ? 1 : 0} top='0' overflow='hidden' left='0' pointerEvents='none' boxSizing='border-box' w='100%' h='100%'>
      <Spinner color={colors.lightblue}/>
    </Center>
)