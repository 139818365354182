import { DeleteIcon } from '@chakra-ui/icons'
import { Collapse, Flex, IconButton, StackDivider, Text, VStack } from '@chakra-ui/react'
import {
  Field,
  FieldType,
  isField,
  isRecordField,
  RecordField,
} from '@testme/shared'
import { FC, useMemo } from 'react'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import { Field as FinalField } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { GhostButton } from '../../shared/Buttons'
import { ExpandOnMount } from '../../shared/ExpandOnMount'
import { Condition } from '../Condition'
import { BooleanComponent } from './boolean'
import { FileComponent } from './file'
import { CurrencyAmountComponent } from './number'
import { SelectComponent } from './select'
import { TextComponent } from './text'
import { TextAreaComponent } from './textarea'
import { FormProps, InputProps } from './types'
import { defaultValidate } from './utils'

const makeOnDragEndFunction = (fields: any) => (result: DropResult) => {
  // dropped outside the list
  if (!result.destination) {
    return
  }
  fields.move(result.source.index, result.destination.index)
}

export const RecordComponent: FC<{ field: RecordField; name: string }> = ({
  field,
  name,
}) => {
  return (
    <FieldArray name={name}>
      {({ fields }) => (
        <DragDropContext onDragEnd={makeOnDragEndFunction(fields)}>
          <Droppable droppableId='droppable'>
            {(provided, snapshot) => (
              <VStack
                ref={provided.innerRef}
                divider={<StackDivider opacity={0.3} />}
                w='100%'
                py={3}
                position='relative'
                // bg='blackAlpha.'
                p={2}
                align='flex'
                spacing={2}
              >
                {field.label || null}
                {fields.map((id, index) => (
                  <ExpandOnMount style={{position: 'relative'}} in key={id}>
                    <Flex align='center'>
                    <Flex flex={1} align='center'>
                      
                    <FormElement validate name={id} field={field.itemField} />
                      </Flex>
                      <IconButton size='xs'
                      variant='ghost'
                      _hover={{background: 'whiteAlpha.300'}}
                      aria-label='delete'
                      color='red.600'
                      icon={
                      <DeleteIcon onClick={() => fields.remove(index)}/>
                      } 
                      />

                    </Flex>

                  </ExpandOnMount>
                ))}
                <Flex>
                  <GhostButton
                    // // mr='auto'
                    // width='auto'
                    w='100%'
                    size='xs'
                    onClick={() => fields.push({ id: `${Date.now()}` })}
                  >
                    + NEW
                  </GhostButton>
                </Flex>
              </VStack>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </FieldArray>
  )
}

const Components: Record<Field['_type'], FC<InputProps<Field>>> = {
  text: TextComponent as FC<InputProps<Field>>,
  file: FileComponent as FC<InputProps<Field>>,
  boolean: BooleanComponent as FC<InputProps<Field>>,
  select: SelectComponent as FC<InputProps<Field>>,
  textarea: TextAreaComponent as FC<InputProps<Field>>,
  currency: CurrencyAmountComponent as FC<InputProps<Field>>,
}
export const Input = (props: InputProps<Field>) => {
  const {
    field,
    meta: { error, active, touched },
  } = props
  const color = useMemo(() => {
    if (error && touched) return '#ff7777'
    if (active) return 'rgba(255,255,255,0.7)'
    return 'transparent'
  }, [error, active, touched])
  const styles = useMemo(() => {
    switch (field._type) {
      case 'text':
      case 'textarea':
      case 'currency':
        return { background: 'whiteAlpha.200', boxShadow: `0 0 7px ${color}` }
      default:
        return {}
    }
  }, [field, color])
  const Component = Components[field._type as FieldType]
  return (
    <VStack align='flex-start' width='100%' p={1}>
      <Flex width='100%' borderRadius={6} overflow='hidden' {...styles}>
        <Component {...props} />
      </Flex>
      <Collapse in={!!error && !!touched && !active}>
        <Text fontSize='sm' px={4} color='#ffaaaa'>
          {error}
        </Text>
      </Collapse>
    </VStack>
  )
}

const FieldComponent = ({
  field,
  name,
  validate,
}: {
  field: Field
  name: string
  validate?: boolean
}) => {
  const {condition} = field
  const body =  (
    <FinalField
      validate={
        validate
          ? field.validate || ((d) => defaultValidate(field, d))
          : undefined
      }
      key={name}
      name={name}
      render={(props) => <Input field={field as Field} {...props} />}
    />
  )
  return condition ? <Condition {...condition}>
    {body}
  </Condition> : body
}

export const FormElement = ({
  field,
  name,
  validate,
}: {
  field: FormProps['field']
  name?: string
  validate?: boolean
}) => {
  const fields = useMemo(() => {
    if (isField(field) || isRecordField(field))
      return [{ name: 'value', field }]
    else
      return Object.entries(field.children).map(([childName, field]) => ({
        name: `${name ? name + '.' : ''}${childName}`,
        field,
      }))
  }, [field, name])
  const body = (
    <VStack w='100%'>
      {fields.map((f) => {
        if (isField(f.field)) {
          return (
            <FieldComponent
              key={f.name}
              name={f.name}
              field={f.field}
              validate={validate}
            />
          )
        } else if (isRecordField(f.field)) {
          return <RecordComponent key={f.name} name={f.name} field={f.field} />
        } else {
          return (
            <FormElement
              validate={validate}
              key={f.name}
              name={f.name}
              field={f.field}
            />
          )
        }
      })}
    </VStack>
  )
  return field.condition ? (
    <Condition {...field.condition}>{body}</Condition>
  ) : (
    body
  )
}
