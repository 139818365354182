import {
  Box,
  Collapse,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useToast
} from '@chakra-ui/react'
import { BooleanField, Player, SelectField } from '@testme/shared'
import { FORM_ERROR } from 'final-form'
import { FC, useCallback, useMemo, useState } from 'react'
import { getPaymentClientSecret } from '../../api/session'
import { useMyUser } from '../../api/users'
import { colors } from '../../constants'
import { useApp } from '../../hooks'
import { FieldMapForm } from '../forms/Form'
import { GhostButton } from '../shared/Buttons'
import { GlowText } from '../shared/Text'
import { Checkout } from './Checkout'
import { FormatPreview } from './FormatPreview'

const shouldSaveCard: BooleanField = {
  _type: 'boolean',
  label: 'Save this card for later',
  condition: {when: 'paymentSource', is: 'new'}
}

const useSessionFormatField = (player?: Player) => {
  const {user} = useMyUser()
  const cards = user?.stripe?.cards
  const cardOptions = useMemo(() => (cards || []).map(
    ({stripeId: id, brand, last4}) => ({id, text: `${brand.toUpperCase()} - ${last4}`})
    ), [cards])
  const {field, optionId} = useMemo(() => {
    if (!player?.sessionFormats) return {}
    const optionId: SelectField = {
          _type: 'select',
          options: player.sessionFormats,
          renderOption: FormatPreview,
          placeholder: 'Select a format...',
        }
    const paymentSource: SelectField = {
        _type: 'select',
          placeholder: 'Payment',
          options: [
            ...cardOptions,
            {
              id: 'new',
              text: 'New Card'
            }
          ]
        }
    const field =  {
      children: {
        optionId,
        paymentSource,
        shouldSaveCard
      },
    }
    return {field, optionId, paymentSource}
  }, [player, cardOptions])
  const {options} = optionId || {}
  const initValue = useMemo(() => ({
    optionId: options?.[0].id,
    paymentSource: user?.stripe?.cards?.[0]?.stripeId || 'new',
  }), [options, user])
  return {field ,initValue}
}



export const SessionSelect: FC<{
  playerId?: string
  onClientSecret: (data: {clientSecret: string, isNewCard: boolean}) => void
}> = ({ playerId, onClientSecret }) => {
  const { selectedPlayer } = useApp()
  const { data: player } = selectedPlayer || {}
  const toast = useToast()
  const {field, initValue} = useSessionFormatField(player)
  const getClientSecret = useCallback(
    async (d: any) => {
      if (!playerId) return
      console.log(d)
      return getPaymentClientSecret({ player: playerId, ...d })
        .then((res) => {
          onClientSecret({clientSecret: res.data, isNewCard: d?.paymentSource === 'new'})
          return undefined
        })
        .catch((err) => {
          console.log(err)
          toast({ status: 'error', description: err.message })
          return { [FORM_ERROR]: 'Error getting payment intent' }
        })
    },
    [toast, playerId, onClientSecret]
  )
  return (
    <FieldMapForm
      onSubmit={getClientSecret}
      value={initValue}
      renderFooter={({ handleSubmit, submitting }) => {
        return (
          <Flex flexFlow='column' align='center' w='100%'>
            <GhostButton
              mx='auto'
              isLoading={submitting}
              // isDisabled
              title='Checkout coming soon!'
              onClick={handleSubmit}
            >
              CONFIRM
            </GhostButton>
          </Flex>
        )
      }}
      field={field || {children: {}}}
    />
  )
}

export const JoinQueueModal = () => {
  const { modals, selectedPlayer } = useApp()
  const { data: player, id } = selectedPlayer || {}
  const modal = modals!.joinQueue
  const handleOnClientSecret = useCallback(({
    isNewCard, 
    clientSecret
  }: {isNewCard: boolean, clientSecret: string}) => {
    if(!isNewCard) modal.onClose()
    else setClientSecret(clientSecret)
  }, [modal])
  const [clientSecret, setClientSecret] = useState<string | null>(null)

  return (
    <Modal isCentered {...modal}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody py={4}>
          <GlowText mb={4} color={colors.lightblue} fontSize='1.4rem'>
            Practice against {player?.tag || ''}
          </GlowText>
          <Collapse in={!clientSecret}>
            {player?.sessionFormats?.length ? (
              <SessionSelect
                playerId={id || undefined}
                onClientSecret={handleOnClientSecret}
              />
            ) : (
              <Text>This player hasn't set up their rates</Text>
            )}
          </Collapse>
        </ModalBody>
        <Collapse
          style={{ width: '100%', position: 'relative' }}
          in={!!clientSecret}
        >
          <Box w='100%' mb={6} px={6}>
            {clientSecret ? (
              <Checkout
                onResponse={() => {
                  setClientSecret(null)
                  modal.onClose()
                }}
                clientSecret={clientSecret}
              />
            ) : null}
          </Box>
        </Collapse>
      </ModalContent>
    </Modal>
  )
}
